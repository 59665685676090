import styled from 'styled-components';
import React from 'react';
import { Text, Icon, Checkbox } from '@audi/audi-ui-react-v2';
export const ModelFilterContainer = styled.div `
  width: 100%;
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    width: ${(props) => (props.layoutWidth === '50' ? 'calc(50% - 20px)' : '100%')};
  }
`;
export const ModelFilterAccordionContainer = styled.div `
  max-width: 100%;
  width: 100%;
  display: grid;
  column-gap: var(${(props) => props.theme.responsive.spacing.xs});
  grid-template-columns: repeat(2, 1fr);
  margin-bottom: var(${(props) => props.theme.responsive.spacing.xxl});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    position: relative;
    column-gap: var(${(props) => props.theme.responsive.spacing.xxl});
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    grid-template-columns: ${(props) => props.layoutWidth === '100' ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)'};
    width: ${(props) => (props.layoutWidth === '50/100' ? 'calc(50% - 20px)' : '100%')};
  }
`;
export const ModelFilterAccordionSectionContent = styled.div `
  display: block;
  margin-top: -1px;
`;
export const ModelFilterAccordionSectionPanel = styled.div `
  --panel-height: 0px;
  box-sizing: border-box;
  height: var(--panel-height);
  overflow: hidden;
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
`;
export const ModelFilterAccordionSectionPanelContent = styled.div `
  --inset: var(${(props) => props.theme.responsive.spacing.xxxl});
  box-sizing: border-box;
  background-color: var(${(props) => props.theme.colors.interaction.tertiary.hover});
  opacity: ${(props) => (props.open ? 1 : 0)};
  position: absolute;
  left: 0;
  width: 100%;
  padding: var(${(props) => props.theme.responsive.spacing.xl})
    var(${(props) => props.theme.responsive.spacing.l})
    var(${(props) => props.theme.responsive.spacing.xxs})
    var(${(props) => props.theme.responsive.spacing.l});
  z-index: ${(props) => (props.open ? '1' : '0')};

  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    padding-bottom: var(${(props) => props.theme.responsive.spacing.xs});
  }

  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    padding-bottom: 20px;
  }
`;
export const StyledLegend = styled.legend `
  display: flex;
  margin-block-end: var(${(props) => props.theme.responsive.spacing.l});
`;
export const AllModelsCheckbox = styled((props) => React.createElement(Checkbox, Object.assign({}, props))) `
  input {
    height: 20px !important;
  }
  /* TODO remove once DesOps removes (optional) label from checkboxes */
  label > span {
    display: none;
  }
`;
export const CarlineGroupCheckboxes = styled.div `
  width: 100%;
  display: grid;
  grid-auto-flow: row;
  column-gap: var(${(props) => props.theme.responsive.spacing.xxl});
  grid-template-columns: repeat(1, 1fr);

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    grid-template-columns: ${(props) => props.layoutWidth === '100' ? 'repeat(4, 1fr)' : 'repeat(2, 1fr)'};
  }
`;
export const StyledButton = styled.button `
  border: none 0;
  padding: 0;
  padding-bottom: var(
    ${(props) => props.open ? props.theme.responsive.spacing.m : props.theme.responsive.spacing.l}
  );
  background-color: var(
    ${(props) => props.open
    ? props.theme.colors.interaction.tertiary.hover
    : props.lightMode
        ? props.theme.colors.base.grey[5]
        : props.theme.colors.base.grey[80]}
  );
  width: calc(100% + 24px);
  position: relative;
  padding-left: var(
    ${(props) => (props.nthChild % 2 !== 0 ? props.theme.responsive.spacing.l : '0')}
  );
  left: ${(props) => (props.nthChild % 2 !== 0 ? '-24px' : '0')};
  padding-right: var(
    ${(props) => (props.nthChild % 2 === 0 ? props.theme.responsive.spacing.l : '0')}
  );

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    width: 100%;
    position: static;
    padding-left: 0;
    padding-right: 0;
    left: 0;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    background-color: var(
      ${(props) => props.open
    ? props.theme.colors.interaction.tertiary.hover
    : props.lightMode
        ? props.theme.colors.base.brand.white
        : props.theme.colors.base.grey[80]}
    );
  }

  :hover {
    cursor: pointer;

    @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
      background-color: var(${(props) => props.theme.colors.interaction.tertiary.hover});
    }

    > img {
      scale: 1.05;
    }
  }
`;
export const TextWrapper = styled.div `
  margin-top: var(${(props) => props.theme.responsive.spacing.xs});
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: var(
    ${(props) => (props.nthChild % 2 === 0 ? props.theme.responsive.spacing.m : 0)}
  );
  margin-right: var(
    ${(props) => (props.nthChild % 2 !== 0 ? props.theme.responsive.spacing.m : 0)}
  );

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-left: var(${(props) => props.theme.responsive.spacing.m});
    margin-right: var(${(props) => props.theme.responsive.spacing.m});
  }
`;
export const CarlineGroupAndAmountWrapper = styled.div `
  text-align: left;
`;
export const Wrapper = styled.div `
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: var(${(props) => props.theme.responsive.spacing.xxs});
`;
export const StyledText = styled((props) => React.createElement(Text, Object.assign({}, props))) `
  float: left;
`;
export const StyledImg = styled.img `
  object-fit: contain;
  width: 100%;
  height: 17.7vw;

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    height: 18.3vw;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    height: 18.2vw;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    height: 8.2vw;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    height: 8.1vw;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    height: 162.5px;
  }
`;
export const StyledImagePlaceholder = styled.div `
  object-fit: contain;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 17.7vw;
  padding-inline-start: var(${(props) => props.theme.responsive.spacing.s});
  padding-inline-end: var(${(props) => props.theme.responsive.spacing.s});
  box-sizing: border-box;

  @media (min-width: ${(props) => props.theme.breakpoints.s}px) {
    height: 18.3vw;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    height: 18.2vw;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    height: 8.2vw;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    height: 8.1vw;
    padding: var(${(props) => props.theme.responsive.spacing.m});
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    height: 162.5px;
  }

  :hover {
    cursor: pointer;

    @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
      background-color: var(${(props) => props.theme.colors.interaction.tertiary.hover});
    }
  }
`;
export const StyledIcon = styled((props) => React.createElement(Icon, Object.assign({}, props))) `
  transform: rotateZ(${(props) => (props.open ? -180 : 0)}deg);
`;
export const ChosenModelsWrapper = styled.div `
  margin-top: var(${(props) => props.theme.responsive.spacing.m});
  padding: var(${(props) => props.theme.responsive.spacing.xxs})
    var(${(props) => props.theme.responsive.spacing.s});
  display: ${(props) => (props.open ? 'none' : 'flex')};
  align-items: center;
  background-color: var(${(props) => props.theme.colors.interaction.tertiary.hover});
  width: fit-content;
  margin-left: var(
    ${(props) => (props.nthChild % 2 === 0 ? props.theme.responsive.spacing.m : 0)}
  );
  margin-right: var(
    ${(props) => (props.nthChild % 2 !== 0 ? props.theme.responsive.spacing.m : 0)}
  );

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-left: var(${(props) => props.theme.responsive.spacing.m});
    margin-right: var(${(props) => props.theme.responsive.spacing.m});
  }
`;
