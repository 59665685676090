import styled from 'styled-components';
export const RangeFilterContainer = styled.div `
  width: 100%;
  margin-bottom: var(${(props) => props.theme.responsive.spacing.s});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    width: calc(50% - 18px);
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin-bottom: var(${(props) => props.theme.responsive.spacing.m});
    width: ${(props) => (props.layoutWidth === '50' ? 'calc(50% - 20px)' : '100%')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    margin-bottom: 28px;
    width: ${(props) => (props.layoutWidth === '50' ? 'calc(50% - 26px)' : '100%')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    margin-bottom: var(${(props) => props.theme.responsive.spacing.xl});
    width: ${(props) => (props.layoutWidth === '50' ? 'calc(50% - 34px)' : '100%')};
  }
`;
export const OptionsContainer = styled.div `
  display: flex;
  align-items: end;
  margin-bottom: var(${(props) => props.theme.responsive.spacing.l});

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    width: ${(props) => (props.layoutWidth === '50/100' ? 'calc(50% - 20px)' : '100%')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    width: ${(props) => (props.layoutWidth === '50/100' ? 'calc(50% - 26px)' : '100%')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    width: ${(props) => (props.layoutWidth === '50/100' ? 'calc(50% - 34px)' : '100%')};
  }
`;
export const StyledHeadingContainer = styled.div `
  display: flex;
  margin-block-end: var(${(props) => props.theme.responsive.spacing.l});

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    width: ${(props) => (props.layoutWidth === '50/100' ? 'calc(50% - 20px)' : '100%')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    width: ${(props) => (props.layoutWidth === '50/100' ? 'calc(50% - 26px)' : '100%')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xxl}px) {
    width: ${(props) => (props.layoutWidth === '50/100' ? 'calc(50% - 34px)' : '100%')};
  }
`;
export const Option = styled.div `
  flex: 1 1 0px;
`;
export const Spacing = styled.div `
  width: var(${(props) => props.theme.responsive.spacing.s});
`;
