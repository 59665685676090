import React, { useCallback } from 'react';
import { Button } from '@audi/audi-ui-react-v2';
import { NavBarItem } from '../styles/FilterNavigation.styles';
import { useServicesContext } from '../../../context';
import { ANCHOR_DESKTOP, TARGET_DESKTOP } from './Filter';
import { trackClicks } from '../tracking';
import { useI18n } from '@oneaudi/i18n-context';
const FilterNavigationItem = React.forwardRef(({ children, id, selected, onClick, handleNavigationItemClick }, ref) => {
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const navigationHeadlineDesktop = useI18n({
        id: `nemo.sc.filter.navigationHeadlineDesktop`,
        defaultMessage: 'Filter',
    });
    const handleClick = useCallback(() => {
        var _a;
        onClick();
        (_a = document.getElementById(id.replace(`${ANCHOR_DESKTOP}`, `${TARGET_DESKTOP}`))) === null || _a === void 0 ? void 0 : _a.focus();
        const sectionTargetName = id.replace(`${ANCHOR_DESKTOP}`, '');
        const trackingEventName = 'vtp filter - click on category - layer open';
        trackClicks(trackingService, trackingEventName, navigationHeadlineDesktop, 'button', '', false);
        handleNavigationItemClick(sectionTargetName);
    }, [onClick]);
    const props = Object.assign({ onClick: handleClick }, (selected && { 'aria-current': true }));
    return (React.createElement(NavBarItem, { id: id, ref: ref },
        React.createElement(Button, Object.assign({}, props, { icon: "arrow-down", variant: "text", spaceInlineEnd: "xxs", "aria-describedby": "filter-aria-description" }), children)));
});
export default FilterNavigationItem;
