import React from 'react';
import { useServicesContext } from '../../../context';
import { FilterContext } from '../FilterContext';
import { useClearAllDealerFilters } from '../FilterContextHooks';
import { useI18n } from '@oneaudi/i18n-context';
import { ZeroResultsBannerWrapper } from '../styles/LocationFilter.styles';
import { setLocationFilterToDefaultState } from '../utils/locationFilterHelpers';
import { Text, Button, audiDarkTheme, ThemeProvider, audiLightTheme } from '@audi/audi-ui-react-v2';
export const ZeroResultsBanner = ({ inputRef, isDisplayedOnMapTab, }) => {
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    const clearAllDealerFilters = useClearAllDealerFilters();
    const context = React.useContext(FilterContext);
    let { lightMode } = audiContentService.getContent().fields;
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const zeroResultHeadline = useI18n({
        id: `nemo.sc.filter.locationfilter.zeroResultsHeadline`,
        defaultMessage: 'Your search returned no results.',
    });
    const zeroResultBody = useI18n({
        id: `nemo.sc.filter.locationfilter.zeroResultsBody`,
        defaultMessage: 'Please change your filter selection to find a suitable dealer.',
    });
    const zeroResultButton = useI18n({
        id: `nemo.sc.filter.locationfilter.zeroResultsButton`,
        defaultMessage: 'Reset location filter',
    });
    return (React.createElement(ThemeProvider, { theme: lightMode ? audiLightTheme : audiDarkTheme },
        React.createElement(ZeroResultsBannerWrapper, { isDisplayedOnMapTab: isDisplayedOnMapTab, lightMode: lightMode },
            React.createElement(Text, { as: "h4", spaceStackEnd: "m" }, zeroResultHeadline),
            React.createElement(Text, { variant: "copy2", spaceStackEnd: "m" }, zeroResultBody),
            React.createElement(Button, { variant: "text", size: "small", onClick: () => setLocationFilterToDefaultState(getConfiguration(), getEnvironmentConfig(), clearAllDealerFilters, context, inputRef) }, zeroResultButton))));
};
export default ZeroResultsBanner;
