import { useState, useEffect } from 'react';
export const useBreakpoint = () => {
    const [breakpoint, setBreakpoint] = useState();
    const setDeviceType = () => {
        const viewportWidth = window.innerWidth;
        switch (true) {
            case viewportWidth < 375:
                return setBreakpoint('XS');
            case viewportWidth >= 375 && viewportWidth < 768:
                return setBreakpoint('S');
            case viewportWidth >= 768 && viewportWidth < 1024:
                return setBreakpoint('M');
            case viewportWidth >= 1024 && viewportWidth < 1440:
                return setBreakpoint('L');
            case viewportWidth >= 1440 && viewportWidth < 1920:
                return setBreakpoint('XL');
            case viewportWidth >= 1920:
                return setBreakpoint('XXL');
            default:
                return setBreakpoint('XS');
        }
    };
    useEffect(() => {
        setDeviceType();
        window.addEventListener('resize', setDeviceType);
        return () => {
            window.removeEventListener('resize', setDeviceType);
        };
    }, []);
    return breakpoint;
};
