import { useCallback, useState, useEffect, useRef } from 'react';
const cubicBezier = (p0, p1, p2, p3, t) => Math.pow((1 - t), 3) * p0 +
    3 * Math.pow((1 - t), 2) * t * p1 +
    3 * (1 - t) * Math.pow(t, 2) * p2 +
    Math.pow(t, 3) * p3;
const animationCurve = (t) => cubicBezier(0.02, 0.75, 0.5, 1, t);
const animationDuration = 250;
export const useScroll = (scrollContainerRef, scrollContentsRef) => {
    const [canScrollBackward, setCanScrollBackward] = useState(false);
    const [canScrollForward, setCanScrollForward] = useState(false);
    const [contentsOverflow, setContentsOverflow] = useState(false);
    const updateShouldScroll = useCallback(() => {
        if ((scrollContainerRef === null || scrollContainerRef === void 0 ? void 0 : scrollContainerRef.current) && (scrollContentsRef === null || scrollContentsRef === void 0 ? void 0 : scrollContentsRef.current)) {
            const outerWidth = scrollContainerRef.current.clientWidth;
            const innerWidth = scrollContentsRef.current.offsetWidth;
            const { scrollLeft: scrollStart } = scrollContainerRef.current;
            const hasStartOverflow = scrollStart !== 0;
            const hasEndOverflow = Math.abs(scrollStart) < innerWidth - outerWidth - 1;
            setContentsOverflow(hasStartOverflow || hasEndOverflow);
            setCanScrollBackward(hasStartOverflow);
            setCanScrollForward(hasEndOverflow);
        }
    }, [scrollContainerRef, scrollContentsRef]);
    useEffect(updateShouldScroll, [scrollContainerRef, scrollContentsRef, updateShouldScroll]);
    useEffect(() => {
        const observer = new MutationObserver(updateShouldScroll);
        if (typeof window !== 'undefined')
            window.addEventListener('resize', updateShouldScroll);
        let container = null;
        if (scrollContainerRef.current) {
            observer.observe(scrollContainerRef.current, {
                subtree: true,
                childList: true,
                characterData: true,
            });
            container = scrollContainerRef.current;
            scrollContainerRef.current.addEventListener('scroll', updateShouldScroll);
        }
        return () => {
            observer.disconnect();
            if (typeof window !== 'undefined')
                window.removeEventListener('resize', updateShouldScroll);
            container === null || container === void 0 ? void 0 : container.removeEventListener('scroll', updateShouldScroll);
        };
    }, [scrollContainerRef, updateShouldScroll]);
    const frameId = useRef(null);
    const scrollBy = useCallback(({ top: offsetTop, left: offsetLeft } = {}) => {
        if (!scrollContainerRef.current)
            return;
        if (frameId.current)
            cancelAnimationFrame(frameId.current);
        const isSmoothScrollSupported = typeof document !== 'undefined' && 'scrollBehavior' in document.documentElement.style;
        if (isSmoothScrollSupported) {
            scrollContainerRef.current.scrollBy({
                top: offsetTop,
                left: offsetLeft,
                behavior: 'smooth',
            });
        }
        else {
            const { scrollTop: startTop, scrollLeft: startLeft } = scrollContainerRef.current;
            const startTime = Date.now();
            const updateScroll = () => {
                var _a;
                const deltaTime = Date.now() - startTime;
                const linearProgress = Math.min(deltaTime, animationDuration) / animationDuration;
                const curvedProgress = animationCurve(linearProgress);
                (_a = scrollContainerRef.current) === null || _a === void 0 ? void 0 : _a.scrollTo(Object.assign(Object.assign({}, (offsetTop !== undefined && {
                    top: startTop + offsetTop * curvedProgress,
                })), (offsetLeft !== undefined && {
                    left: startLeft + offsetLeft * curvedProgress,
                })));
                frameId.current = linearProgress < 1 ? requestAnimationFrame(updateScroll) : null;
            };
            frameId.current = requestAnimationFrame(updateScroll);
        }
    }, [scrollContainerRef]);
    const scrollIntoView = useCallback((target) => {
        if ((target === null || target === void 0 ? void 0 : target.current) && scrollContainerRef.current) {
            scrollBy({
                left: target.current.offsetLeft - scrollContainerRef.current.scrollLeft,
                top: target.current.offsetTop - scrollContainerRef.current.scrollTop,
            });
        }
    }, [scrollBy, scrollContainerRef]);
    return {
        canScroll: contentsOverflow,
        canScrollBackward,
        canScrollForward,
        scrollBy,
        scrollIntoView,
    };
};
export default useScroll;
