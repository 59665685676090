import React, { useEffect, useState, useRef } from 'react';
import { audiLightTheme, audiDarkTheme, ThemeProvider, Text, Icon } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { VisuallyHidden } from '../utils/utils';
import { FilterNavigationContainer, FilterNavigationDiv, FilterNavigationMobile, FilterNavigationMobileButton, FilterNavigationDesktop, FilterNavigationCategories, FilterHeadline, } from '../styles/FilterNavigation.styles';
import { useSelector } from 'react-redux';
import { SELECTORS } from '@oneaudi/stck-store';
import FilterNavigationBar from './FilterNavigationBar';
import FilterNavigationItem from './FilterNavigationItem';
import { SECTION, ANCHOR_DESKTOP } from './Filter';
import { useServicesContext } from '../../../context';
import { trackClicks, trackImpression } from '../tracking';
import { useCountSelectedFilters } from '../FilterContextHooks';
const FilterNavigation = ({ configuredFilters, isFilterOverlayOpened, handleNavigationItemClick, openOverlay, }) => {
    const countSelectedFilters = useCountSelectedFilters();
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const navigationHeadlineMobile = useI18n({
        id: `nemo.sc.filter.navigationHeadlineMobile`,
        defaultMessage: 'Filter',
    });
    const navigationHeadlineDesktop = useI18n({
        id: `nemo.sc.filter.navigationHeadlineDesktop`,
        defaultMessage: 'Filter',
    });
    const filterAriaDescription = useI18n({
        id: `nemo.sc.filter.filterNavigationAriaDescription`,
        defaultMessage: 'An extensive list of filters is available to help you find the right car. A click on one of the categories in this navigation will take you to the corresponding section of the filter list. Each section contains several filters.',
    });
    const containerRef = useRef(null);
    const [isFilterHeaderFixed, setIsFilterHeaderFixed] = useState(false);
    const [navigationContainerHeight, setNavigationContainerHeight] = useState(0);
    const [navigationBarState, setNavigationBarState] = useState('');
    const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window === null || window === void 0 ? void 0 : window.innerWidth : 320);
    const allActiveFiltersMap = useSelector((state) => SELECTORS.FILTERS.getAllActiveFiltersMap(state));
    const areChipsRendered = (allActiveFiltersMap === null || allActiveFiltersMap === void 0 ? void 0 : allActiveFiltersMap.size) > 0;
    const handleResize = () => {
        setWindowWidth(typeof window !== 'undefined' ? window === null || window === void 0 ? void 0 : window.innerWidth : 320);
    };
    useEffect(() => {
        const trackingValue = windowWidth < 768 ? 'button' : 'bar';
        trackImpression(trackingService, trackingValue, true);
    }, []);
    useEffect(() => {
        if (typeof window !== 'undefined') {
            window === null || window === void 0 ? void 0 : window.addEventListener('resize', handleResize);
        }
        return () => {
            if (typeof window !== 'undefined') {
                window === null || window === void 0 ? void 0 : window.removeEventListener('resize', handleResize);
            }
        };
    }, [windowWidth]);
    useEffect(() => {
        if (!isFilterOverlayOpened) {
            setNavigationBarState(`${SECTION}1${ANCHOR_DESKTOP}`);
        }
    }, [isFilterOverlayOpened]);
    useEffect(() => {
        const onScroll = () => {
            if (!containerRef.current) {
                return;
            }
            const rect = containerRef.current.getBoundingClientRect();
            const newFixed = rect.top <= 0;
            if (newFixed !== isFilterHeaderFixed) {
                setIsFilterHeaderFixed(newFixed);
            }
            if (rect.height > 0 && rect.height !== navigationContainerHeight) {
                setNavigationContainerHeight(rect.height);
            }
        };
        if (typeof window !== 'undefined') {
            window.removeEventListener('scroll', onScroll);
            window.addEventListener('scroll', onScroll, { passive: true });
        }
        return () => {
            if (typeof window !== 'undefined')
                window.removeEventListener('scroll', onScroll);
        };
    }, [
        isFilterHeaderFixed,
        setIsFilterHeaderFixed,
        navigationContainerHeight,
        setNavigationContainerHeight,
    ]);
    const handleClick = () => {
        const trackingEventName = 'vtp filter - click on category - layer open';
        trackClicks(trackingService, trackingEventName, navigationHeadlineMobile, 'button', '', false);
        openOverlay(navigationHeadlineMobile, true);
    };
    return (React.createElement(FilterNavigationContainer, { ref: containerRef, areChipsRendered: areChipsRendered },
        React.createElement(FilterNavigationDiv, { isFixed: isFilterHeaderFixed },
            React.createElement(ThemeProvider, { theme: audiLightTheme },
                React.createElement(FilterNavigationMobile, { "data-testid": "FilterBar", id: "filter-headline-mobile" },
                    React.createElement(FilterNavigationMobileButton, { onClick: handleClick }, navigationHeadlineMobile)),
                React.createElement(FilterNavigationDesktop, { "data-testid": "FilterBar" },
                    React.createElement(ThemeProvider, { theme: audiDarkTheme },
                        React.createElement(FilterHeadline, { id: "filter-headline-desktop" },
                            React.createElement(Icon, { name: "filter", size: "small", variant: "primary", spaceInlineEnd: "xs" }),
                            React.createElement(Text, { as: "h2", variant: "copy1", spaceInlineEnd: "xl" }, navigationHeadlineDesktop),
                            React.createElement(VisuallyHidden, { id: "filter-aria-description" }, filterAriaDescription)),
                        React.createElement(FilterNavigationCategories, null,
                            React.createElement(FilterNavigationBar, { id: "nav-bar__scrollingHeader", selected: navigationBarState, onSelect: setNavigationBarState }, configuredFilters.map((configuredCategory, index) => {
                                return (React.createElement(FilterNavigationItem, { id: `${SECTION}${index + 1}${ANCHOR_DESKTOP}`, key: index, handleNavigationItemClick: handleNavigationItemClick }, `${configuredCategory.filterCategory} ${countSelectedFilters.increment(configuredCategory).totalCount}`));
                            })))))))));
};
export default FilterNavigation;
