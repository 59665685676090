import React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { audiDarkTheme, ThemeProvider } from '@audi/audi-ui-react-v2';
import { useGoogleConsent } from '@oneaudi/two-click';
import { MapBlockedContainer, MapConsentRequestButton, StyledEyeCrossedIcon, StyledApprovalText, } from '../styles/LocationFilter.styles';
import { useDesktopOrMobileView } from '../../../hooks';
import CookieConsentRequest from './CookieConsentRequest';
import { FilterContext } from '../FilterContext';
import { useServicesContext } from '../../../context';
export const MapBlockedWrapper = ({ mobileNavBarState, mapConsentButtonText, openConsentOverlay, setIsTwoClickOverlayOpened, }) => {
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    const consent = useGoogleConsent();
    const context = React.useContext(FilterContext);
    const { setWholeMarketDealersFetch } = context;
    let { lightMode } = audiContentService.getContent().fields;
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const isDesktop = useDesktopOrMobileView('1024px') === 'desktop';
    const mapConsentRequestText = useI18n({
        id: `nemo.sc.filter.locationfilter.cookieConsentRequestMap.Text`,
        defaultMessage: 'Content requires your approval',
    });
    return (React.createElement(React.Fragment, null, !consent &&
        ((!isDesktop && mobileNavBarState === 'nav-bar-location-search-mobile--map-view') ||
            isDesktop) && (React.createElement(React.Fragment, null,
        isDesktop && (React.createElement(CookieConsentRequest, { mapConsentButtonText: mapConsentButtonText, openConsentOverlay: openConsentOverlay, setIsTwoClickOverlayOpened: setIsTwoClickOverlayOpened })),
        React.createElement(MapBlockedContainer, null,
            React.createElement(StyledEyeCrossedIcon, { size: "large", variant: "primary", name: "eye-crossed", spaceStackEnd: "s", lightMode: lightMode }),
            React.createElement(StyledApprovalText, { lightMode: lightMode }, mapConsentRequestText),
            !isDesktop && (React.createElement(ThemeProvider, { theme: audiDarkTheme },
                React.createElement(MapConsentRequestButton, { variant: "primary", size: "small", spaceStackStart: "l", onClick: () => {
                        setWholeMarketDealersFetch(true);
                        openConsentOverlay();
                        setIsTwoClickOverlayOpened(true);
                    } }, mapConsentButtonText))))))));
};
export default MapBlockedWrapper;
