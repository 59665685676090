export const trackFeatureAppReady = (trackingService, availableCategories, results, appliedFilters, isUsedCarMkt) => {
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: 'feature_app_ready',
                    eventName: 'vtp filter - feature app ready',
                },
                attributes: {
                    componentName: 'vtp-filter',
                    label: '',
                    targetURL: '',
                    clickID: '',
                    elementName: '',
                    value: '',
                    pos: '',
                },
            },
            componentUpdate: {
                attributes: {
                    implementer: 37,
                    availableCategories,
                    search: {
                        name: isUsedCarMkt ? 'vtp search - used' : 'vtp search - new',
                        term: '',
                        results,
                        filter: appliedFilters,
                    },
                },
            },
        });
    }
};
export const trackClicks = (trackingService, eventName, label, elementName, value, isTriggeredByOpeningBrowserSearch) => {
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: isTriggeredByOpeningBrowserSearch ? 'content' : 'navigation',
                    eventName,
                },
                attributes: {
                    componentName: 'vtp-filter',
                    label,
                    targetURL: '',
                    clickID: '',
                    elementName,
                    value,
                    pos: '',
                },
            },
        });
    }
};
export const trackOneFilterChange = (trackingService, eventName, label, value, changedFilter, relatedFilters, results, isUsedCarMkt) => {
    const updatedRelatedFilters = relatedFilters.length > 0
        ? relatedFilters.filter((item, index, array) => array.findIndex((other) => other.id === item.id) === index)
        : relatedFilters;
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: 'filter',
                    eventName,
                },
                attributes: {
                    componentName: 'vtp-filter',
                    label,
                    targetURL: '',
                    clickID: '',
                    elementName: '',
                    value,
                    pos: '',
                    relatedFilters: changedFilter,
                },
            },
            componentUpdate: {
                attributes: {
                    search: {
                        name: isUsedCarMkt ? 'vtp search - used' : 'vtp search - new',
                        term: '',
                        results,
                        filter: updatedRelatedFilters,
                    },
                },
            },
        });
    }
};
export const trackRemoveAllFilters = (trackingService, elementName, label, relatedFilters, results, isUsedCarMkt) => {
    const idsToRemove = new Set();
    relatedFilters.forEach((item) => {
        item.values.forEach((value) => idsToRemove.add(value));
    });
    const cleanRelatedFilters = relatedFilters.filter((item) => !idsToRemove.has(item.id));
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: 'filter',
                    eventName: 'vtp filter - remove all filter',
                },
                attributes: {
                    componentName: 'vtp-filter',
                    label,
                    targetURL: '',
                    clickID: '',
                    elementName,
                    value: '',
                    pos: '',
                    relatedFilters: cleanRelatedFilters,
                },
            },
            componentUpdate: {
                attributes: {
                    search: {
                        name: isUsedCarMkt ? 'vtp search - used' : 'vtp search - new',
                        term: '',
                        results,
                        filter: [],
                    },
                },
            },
        });
    }
};
export const trackImpression = (trackingService, value, overalImpression) => {
    if (trackingService) {
        trackingService.track({
            event: {
                eventInfo: {
                    eventAction: 'impression',
                    eventName: overalImpression
                        ? 'vtp filter - impression'
                        : 'vtp filter - category impression',
                },
                attributes: {
                    componentName: 'vtp-filter',
                    label: '',
                    targetURL: '',
                    clickID: '',
                    elementName: '',
                    value,
                    pos: '',
                },
            },
        });
    }
};
