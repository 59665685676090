import React from 'react';
import { Button, audiDarkTheme, ThemeProvider, Text, Icon, NavigationBar, NavigationItem, audiLightTheme, } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { FilterHeadline, FilterOverlayNavigationMobile, FilterOverlayNavigationHeaderMobile, AccordionContainer, FilterOverlayNavigationDesktopContainer, FilterOverlayNavigationDesktop, FilterOverlayNavigationDesktopWrapper, FilterOverlayNavigationCategoriesDesktop, } from '../styles/FilterOverlay.styles';
import { SECTION, ANCHOR_MOBILE, OVERLAY_ANCHOR_DESKTOP } from './Filter';
import { useSelector } from 'react-redux';
import { SELECTORS } from '@oneaudi/stck-store';
import { FilterOverlayAccordion } from './FilterOverlayAccordion';
import { FilterOverlayAccordionSection } from './FilterOverlayAccordionSection';
import { useDesktopOrMobileView, useScsUrlParts } from '../../../hooks';
import { useServicesContext } from '../../../context';
import { trackClicks, trackRemoveAllFilters } from '../tracking';
import { EquipmentFilter } from './EquipmentFilter';
import { useCountSelectedFilters } from '../FilterContextHooks';
import { FilterContext } from '../FilterContext';
export const FilterOverlayNavigation = ({ configuredFilters, isFilterOverlayOpened, equipmentFilterCategories, overlayNavBarState, overlayRef, clearAllFilters, closeOverlay, navigateToContentSection, renderFilters, }) => {
    const countSelectedFilters = useCountSelectedFilters();
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    let { lightMode } = audiContentService.getContent().fields;
    const context = React.useContext(FilterContext);
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const { isUsedCarMkt } = useScsUrlParts();
    const overlayHeadlineMobile = useI18n({
        id: `nemo.sc.filter.overlayHeadlineMobile`,
        defaultMessage: 'Filter',
    });
    const overlayHeadlineDesktop = useI18n({
        id: `nemo.sc.filter.overlayHeadlineDesktop`,
        defaultMessage: 'Filter',
    });
    const overlayCloseButtonAriaLabel = useI18n({
        id: `nemo.sc.filter.overlayCloseButtonAriaLabel`,
        defaultMessage: 'Close filter menu',
    });
    const overlayClearAllFiltersButtonAriaLabel = useI18n({
        id: `nemo.sc.filter.overlayClearAllFiltersButtonAriaLabel`,
        defaultMessage: 'Clear all filters',
    });
    const allSelectedFilters = useSelector((state) => SELECTORS.FILTERS.getSelectedFiltersMap(state));
    const totalResults = useSelector((state) => SELECTORS.VEHICLES.getTotalCountState(state));
    const isDesktopOrTablet = useDesktopOrMobileView('768px') === 'desktop';
    const handleCloseOverlay = () => {
        const trackingEventName = 'vtp filter - close filters - layer close';
        trackClicks(trackingService, trackingEventName, '', 'icon', '', false);
        closeOverlay();
    };
    const handleEraseButtonClick = () => {
        const trackingRelatedFilters = [];
        [...allSelectedFilters.values()].forEach((filter) => {
            const relatedFilter = { id: filter.id, values: filter.values || [] };
            trackingRelatedFilters.push(relatedFilter);
        });
        trackRemoveAllFilters(trackingService, 'icon', '', trackingRelatedFilters, totalResults, isUsedCarMkt);
        clearAllFilters();
    };
    return (React.createElement(React.Fragment, null, isDesktopOrTablet ? (React.createElement(FilterOverlayNavigationDesktopContainer, null,
        React.createElement(FilterOverlayNavigationDesktop, null,
            React.createElement(FilterOverlayNavigationDesktopWrapper, null,
                React.createElement(ThemeProvider, { theme: audiDarkTheme },
                    React.createElement(FilterHeadline, null,
                        React.createElement(Icon, { name: "filter", size: "small", variant: "primary", spaceInlineEnd: "xs" }),
                        React.createElement(Text, { as: "h2", variant: "copy1", spaceInlineEnd: "xl" }, overlayHeadlineDesktop))),
                React.createElement(FilterOverlayNavigationCategoriesDesktop, null,
                    React.createElement(NavigationBar, { id: "nav-bar-overlay__scrolling", selected: overlayNavBarState, onSelect: (id) => {
                            navigateToContentSection(id);
                        } }, configuredFilters.map((configuredCategory, index) => {
                        return (React.createElement(NavigationItem, { id: `${SECTION}${index + 1}${OVERLAY_ANCHOR_DESKTOP}`, key: index }, `${configuredCategory.filterCategory} ${countSelectedFilters.increment(configuredCategory).totalCount}`));
                    })))),
            React.createElement(Button, { variant: "icon-tertiary", icon: "cancel", size: "small", spaceInlineStart: "xs", onClick: handleCloseOverlay, "aria-label": overlayCloseButtonAriaLabel, "data-tracking-exclude": true })))) : (React.createElement(FilterOverlayNavigationMobile, null,
        React.createElement(FilterOverlayNavigationHeaderMobile, null,
            React.createElement(Button, { variant: "icon-tertiary", icon: "back", onClick: handleCloseOverlay, "aria-label": overlayCloseButtonAriaLabel, "data-tracking-exclude": true }),
            React.createElement(Text, { as: "h2", variant: "order4", weight: "bold", spaceStackStart: "l", spaceStackEnd: "l" }, overlayHeadlineMobile),
            React.createElement(Button, { variant: "icon-tertiary", icon: "erase", spaceInlineEnd: "xs", onClick: handleEraseButtonClick, "aria-label": overlayClearAllFiltersButtonAriaLabel })),
        React.createElement(AccordionContainer, { className: isFilterOverlayOpened ? 'active' : '' },
            React.createElement(ThemeProvider, { theme: lightMode ? audiLightTheme : audiDarkTheme },
                React.createElement(FilterOverlayAccordion, { isFilterOverlayOpened: isFilterOverlayOpened, overlayRef: overlayRef }, configuredFilters.map((configuredCategory, index) => {
                    if (configuredCategory.isEquipmentFilter && equipmentFilterCategories) {
                        return (React.createElement(FilterOverlayAccordionSection, { key: index, id: `${SECTION}${index + 1}${ANCHOR_MOBILE}`, label: `${configuredCategory.filterCategory} ${countSelectedFilters.increment(configuredCategory).totalCount}` },
                            React.createElement(EquipmentFilter, { configFilterCategory: configuredCategory === null || configuredCategory === void 0 ? void 0 : configuredCategory.filterCategory, equipmentFilterCategories: equipmentFilterCategories, equipmentFilterCategoryLabel: configuredCategory.filterCategory, renderFilters: renderFilters, closeOverlay: closeOverlay, clearAllFilters: clearAllFilters })));
                    }
                    return (React.createElement(FilterOverlayAccordionSection, { key: index, id: `${SECTION}${index + 1}${ANCHOR_MOBILE}`, label: `${configuredCategory.filterCategory} ${countSelectedFilters.increment(configuredCategory).totalCount}` }, renderFilters(configuredCategory, 'mobile')));
                }))))))));
};
export default FilterOverlayNavigation;
