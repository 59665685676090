import React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { StyledCheckboxAllDealersChosen } from '../styles/LocationFilter.styles';
import { areSomeCarsAvailable } from '../utils/locationFilterHelpers';
import { useFilterData } from '../FilterContextHooks';
export const SelectAllDealersCheckbox = ({ allDealersIsChecked, dealersList, visibleIn, handleAllDealersCheck, }) => {
    const filterData = useFilterData();
    const selectAllResults = useI18n({
        id: `nemo.sc.filter.locationfilter.selectAllResults`,
        defaultMessage: 'Select all results',
    });
    return (React.createElement(StyledCheckboxAllDealersChosen, { inputId: `checkbox_all_dealers_${visibleIn}`, checked: allDealersIsChecked && areSomeCarsAvailable(dealersList, filterData), disabled: !dealersList || (dealersList === null || dealersList === void 0 ? void 0 : dealersList.length) === 0 || !areSomeCarsAvailable(dealersList, filterData), onChange: () => handleAllDealersCheck(), hideLabelOptional: true }, selectAllResults));
};
export default SelectAllDealersCheckbox;
