import styled from 'styled-components';
import React from 'react';
import { Divider, Text, Icon, Button, } from '@audi/audi-ui-react-v2';
export const transitionAccordionDuration = 400;
export const FilterOverlayContainer = styled.div `
  display: flex;
  flex-direction: column;
  background-color: var(
    ${(props) => props.lightMode ? props.theme.colors.base.brand.white : props.theme.colors.base.brand.black}
  );
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  z-index: 5006;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;
  opacity: 1;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    height: 100vh;
  }
`;
export const WidthSpacer = styled.div `
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    display: block;
    width: 50%;
  }
`;
export const Container = styled.div `
  @media (max-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-bottom: 89px;
  }
`;
export const FilterHeadline = styled.div `
  display: flex;
  margin: var(${(props) => props.theme.responsive.spacing.l}) 0;
`;
export const OuterChipsContainer = styled.div `
  padding-bottom: var(${(props) => props.theme.responsive.spacing.xxxl});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    background-color: ${(props) => props.lightMode ? 'transparent' : props.theme.colors.base.grey[80]};
    display: flex;
    justify-content: center;
  }
`;
export const ChipsContent = styled.div `
  margin-top: var(${(props) => props.theme.responsive.spacing.xl});
  margin-left: var(${(props) => props.theme.responsive.spacing.l});
  margin-right: var(${(props) => props.theme.responsive.spacing.l});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin-top: 0;
    margin-bottom: 90px;
    margin: 0 40px;
    width: 100%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin: 0 60px 90px 60px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    margin: 0 96px 90px 96px;
    max-width: 1920px;
  }
`;
export const StyledDivider = styled((props) => (React.createElement(Divider, Object.assign({ spaceStackEnd: "xl" }, props)))) `
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: block;
    border-block-start-color: ${(props) => props.theme.colors.ui.divider};
  }
`;
export const StyledTextChosenFilters = styled((props) => (React.createElement(Text, Object.assign({ as: "h3", variant: "order4", spaceStackStart: "xl", spaceStackEnd: "m" }, props)))) `
  color: ${(props) => props.theme.colors.ui.secondary};
`;
export const FilterOverlayNavigationMobile = styled.div `
  display: block;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: none;
  }
`;
export const FilterOverlayNavigationHeaderMobile = styled.div `
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(${(props) => props.theme.colors.base.brand.black});
  border-bottom: solid var(${(props) => props.theme.colors.ui.divider});
  border-width: 1px 0;
  width: 100%;
  z-index: 4;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: none;
  }
`;
export const AccordionContainer = styled.div `
  margin-top: 72px;
  pointer-events: none;

  &.active {
    pointer-events: all;
  }
`;
export const FilterOverlayNavigationDesktopContainer = styled.div `
  display: none;
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: block;
    position: sticky;
    background-color: var(${(props) => props.theme.colors.base.brand.black});
    top: 0;
    z-index: 3;
  }
`;
export const FilterOverlayNavigationDesktop = styled.div `
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1920px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin: 0 40px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin: 0 60px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    margin: 0 auto;
    padding: 0 96px;
  }
`;
export const FilterOverlayBodyContainer = styled.div `
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: block;
    background-color: var(
      ${(props) => props.lightMode ? props.theme.colors.base.brand.white : props.theme.colors.base.grey[80]}
    );
    height: auto;
    padding-bottom: ${(props) => (props.openedChipsContainer ? '0' : '90px')};
  }
`;
export const FilterOverlayContent = styled.div `
  max-width: 1920px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    margin: 0 40px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    margin: 0 60px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    margin: 0 auto;
    padding: 0 96px;
  }
`;
export const ContentSection = styled.div `
  padding-top: var(${(props) => props.theme.responsive.spacing.xxxl});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    padding-bottom: var(${(props) => props.theme.responsive.spacing.s});
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    padding-bottom: 20px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    padding-bottom: 28px;
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    padding-bottom: 32px;
  }
`;
export const HeadingWrapper = styled.div ``;
export const RenderedFiltersWrapper = styled.div `
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;
export const FilterOverlayNavigationDesktopWrapper = styled.div `
  display: flex;
  align-items: center;
  overflow-x: auto;
`;
export const FilterOverlayNavigationCategoriesDesktop = styled.div `
  overflow-x: auto;
`;
export const FilterOverlayFooterContainer = styled.div `
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: var(${(props) => props.theme.colors.base.brand.black});
  border-top: solid var(${(props) => props.theme.colors.ui.divider});
  border-width: 1px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  z-index: 3;
  left: 0;
`;
export const StyledText = styled((props) => (React.createElement(Text, Object.assign({ as: "span", variant: "copy1" }, props)))) `
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    text-wrap: nowrap;
    display: block;
  }
`;
export const StyledResultsButtonWrapper = styled.div `
  margin-right: var(${(props) => props.theme.responsive.spacing.xl});
  margin-left: var(${(props) => props.theme.responsive.spacing.xl});
  width: 100%;
  text-align: center;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    width: 50%;
    display: flex;
    justify-content: end;
    margin-left: 0;
    margin-right: var(${(props) => props.theme.responsive.spacing.s});
  }
`;
export const StyledResultsButton = styled((props) => React.createElement(Button, Object.assign({}, props))) `
  background-color: var(
    ${(props) => (props.lightMode ? props.theme.colors.base.brand.white : 'unset')}
  );
`;
export const StyledDeleteFiltersButtonWrapper = styled.div `
  display: none;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: flex;
    width: 50%;
    margin-left: var(${(props) => props.theme.responsive.spacing.s});
  }
`;
export const FilterOverlayAccordionContainer = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: stretch;
`;
export const FilterAccordionSectionContent = styled.div `
  --button-position: static;
  all: revert;
  margin: 0;
  background-color: var(
    ${(props) => props.isUsedInEquipmentFilter
    ? props.theme.colors.base.grey[90]
    : props.isUsedInLocationFilter && props.lightMode
        ? props.theme.colors.base.brand.white
        : props.theme.colors.base.brand.black}
  );
  z-index: ${(props) => (props.isUsedInLocationFilter ? '0' : '3')};
  position: var(--button-position);
  top: 72px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    background-color: var(
      ${(props) => props.isUsedInEquipmentFilter
    ? props.theme.colors.base.grey[80]
    : props.theme.colors.base.brand.black}
    );
    z-index: ${(props) => props.isUsedInEquipmentFilter || props.isUsedInLocationFilter ? '0' : '2'};
  }

  &:not(:first-child) {
    margin-top: ${(props) => (props.isUsedInLocationFilter ? '0px' : '-1px')};
  }

  &:focus-within {
    z-index: 1;
  }
`;
export const AccordionSectionHeaderButton = styled.button `
  all: revert;
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  text-align: start;
  background: transparent;
  border: solid var(${(props) => props.theme.colors.ui.divider});
  border-width: 1px 0;
  cursor: pointer;
  appearance: none;
  background-color: var(
    ${(props) => (props.lightMode ? props.theme.colors.base.brand.white : 'unset')}
  );
`;
export const AccordionSectionHeaderButtonContent = styled.span `
  display: grid;
  grid-template-columns:
    [section-icon] minmax(0, max-content)
    [section-label] auto
    [arrow-icon] max-content;
  padding: var(${(props) => props.theme.responsive.spacing.m})
    var(${(props) => props.theme.responsive.spacing.l});
  color: var(${(props) => props.theme.colors.interaction.label.default});

  &:hover {
    color: var(${(props) => props.theme.colors.interaction.label.hover});
  }

  &:focus {
    color: var(${(props) => props.theme.colors.interaction.label.focus});
  }
`;
export const StyledIcon = styled((props) => (React.createElement(Icon, Object.assign({ name: "arrow-down", size: "small" }, props)))) `
  grid-column: arrow-icon;
  grid-row: 1 / span 3;
  align-self: center;
  width: 24px;
  height: 24px;
  transform: rotateZ(${(props) => (props.open ? -180 : 0)}deg);
  transition: transform 200ms var(${(props) => props.theme.easing});
  pointer-events: none;
  [dir='rtl'] & {
    transform: rotateZ(${(props) => (props.open ? 180 : 0)}deg);
  }
`;
export const AccordionSectionPanel = styled.div `
  --panel-height: ${(props) => (props.defaultOpen ? 'auto' : '0px')};
  box-sizing: border-box;
  height: var(--panel-height);
  overflow: hidden;
  background-color: var(
    ${(props) => props.lightMode &&
    props.isUsedInLocationFilter &&
    (props.open || props.isDisplayingFocusedDealer)
    ? props.theme.colors.base.brand.white
    : props.lightMode
        ? props.theme.colors.base.grey[5]
        : props.isUsedInEquipmentFilter
            ? props.theme.colors.ui.highlight.tertiary
            : (props === null || props === void 0 ? void 0 : props.isDisplayingFocusedDealer)
                ? props.theme.colors.base.brand.black
                : props.theme.colors.base.grey[80]}
  );
  visibility: ${(props) => (props.open ? 'visible' : 'hidden')};
  transition: ${transitionAccordionDuration}ms var(${(props) => props.theme.easing});
  transition-property: height, visibility;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    background-color: var(
      ${(props) => props.lightMode && props.isUsedInLocationFilter
    ? props.theme.colors.base.grey[5]
    : props.lightMode && !props.isUsedInEquipmentFilter && props.open
        ? props.theme.colors.base.brand.white
        : props.lightMode
            ? props.theme.colors.base.grey[5]
            : props.isUsedInEquipmentFilter
                ? props.theme.colors.ui.highlight.tertiary
                : (props === null || props === void 0 ? void 0 : props.isDisplayingFocusedDealer)
                    ? props.theme.colors.base.brand.black
                    : props.theme.colors.base.grey[80]}
    );
  }
`;
export const AccordionSectionPanelContent = styled.div `
  --inset: var(${(props) => props.theme.responsive.spacing.l});
  --locationfilterpadding: calc(
    var(${(props) => props.theme.responsive.spacing.l}) +
      var(${(props) => props.theme.responsive.spacing.s}) +
      var(${(props) => props.theme.responsive.spacing.s})
  );

  box-sizing: border-box;
  padding-block-start: ${(props) => (props.isUsedInLocationFilter ? '0' : 'var(--inset)')};
  padding-inline-end: var(
    ${(props) => (props.isUsedInLocationFilter ? props.theme.responsive.spacing.s : '--inset')}
  );
  padding-block-end: var(
    ${(props) => (props.isUsedInLocationFilter ? props.theme.responsive.spacing.l : '--inset')}
  );
  padding-inline-start: var(
    ${(props) => (props.isUsedInLocationFilter ? '--locationfilterpadding' : '--inset')}
  );
  transform: ${(props) => !props.open && 'translateY(calc(var(--inset) / 2))'};
  opacity: ${(props) => (props.open ? 1 : 0)};
  transition-delay: ${(props) => (props.open ? transitionAccordionDuration * 0.2 : 0)}ms;
  transition-timing-function: var(${(props) => props.theme.easing});
  transition-duration: ${transitionAccordionDuration * 0.8}ms;
  transition-property: transform, opacity;
`;
export const DealerAccordionItemHeaderWrapper = styled.div `
  display: grid;
  grid-template-columns: 1fr 56px;
  // eslint-disable-next-line no-nested-ternary
  background-color: var(
    ${(props) => props.isDisplayingFocusedDealerBellowMap && props.lightMode
    ? props.theme.colors.base.brand.white
    : props.lightMode && props.open
        ? props.theme.colors.base.brand.white
        : props.lightMode
            ? props.theme.colors.base.grey[5]
            : props.status === 'focused'
                ? props.theme.colors.base.brand.black
                : props.theme.colors.base.grey[80]}
  );
  border-block-start: 1px solid var(${(props) => props.theme.colors.ui.divider});

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    // eslint-disable-next-line no-nested-ternary
    background-color: var(
      ${(props) => props.lightMode
    ? props.theme.colors.base.grey[5]
    : props.status === 'focused'
        ? props.theme.colors.base.brand.black
        : props.theme.colors.base.grey[80]}
    );
  }

  /* prevent vertical stretching when the dealer name line breaks */
  button {
    align-self: start;
  }
`;
