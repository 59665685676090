import React from 'react';
import ModelFilterAccordion from './ModelFilterAccordion';
import ModelFilterAccordionSection from './ModelFilterAccordionSection';
import { useI18n } from '@oneaudi/i18n-context';
import { Text } from '@audi/audi-ui-react-v2';
import { ModelFilterContainer, StyledLegend } from '../styles/ModelFilter.styles';
export const ModelFilter = ({ filterCategory, group, isFilterOverlayOpened, layoutWidth, modelFilterData, visibleIn, }) => {
    const carlineGroupHeadline = useI18n({
        id: `nemo.sc.filter.filtergroups.carline`,
        defaultMessage: 'Model',
    });
    const carlineNames = modelFilterData === null || modelFilterData === void 0 ? void 0 : modelFilterData.carlineNames;
    return (React.createElement(ModelFilterContainer, { layoutWidth: layoutWidth },
        React.createElement(StyledLegend, null,
            React.createElement(Text, { as: "h4", variant: "order3" }, carlineGroupHeadline)),
        React.createElement(ModelFilterAccordion, { isFilterOverlayOpened: isFilterOverlayOpened, layoutWidth: layoutWidth }, modelFilterData &&
            Object.keys(modelFilterData.carlineGroupsStructure).map((key, index) => {
                var _a, _b;
                const carlineGroupPhoto = (_b = (_a = modelFilterData.carlineGroupPhotos) === null || _a === void 0 ? void 0 : _a.find((elem) => elem.carlineGroup === key)) === null || _b === void 0 ? void 0 : _b.carlineGroupPhoto;
                return (React.createElement(ModelFilterAccordionSection, { key: index, carlineGroup: key, carlineGroupName: modelFilterData.carlineGroupsStructure[key].text, carlineGroupCheckboxMembers: modelFilterData.carlineGroupsStructure[key].carlines, carlineGroupPhoto: carlineGroupPhoto, carlineNames: carlineNames, layoutWidth: layoutWidth, nthChild: index + 1, filterCategory: filterCategory, group: group, visibleIn: visibleIn }));
            }))));
};
export default ModelFilter;
