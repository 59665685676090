import React from 'react';
import { DealersListContainer } from '../styles/LocationFilter.styles';
import { useDesktopOrMobileView } from '../../../hooks';
import { DealersListItems } from './DealersListItems';
import { DealersListHeader } from './DealersListHeader';
import { SelectAllDealersCheckbox } from './SelectAllDealersCheckbox';
import DealersListAccordionItems from './DealersListAccordionItems';
import { useGoogleConsent } from '@oneaudi/two-click';
import { ZeroResultsBanner } from './ZeroResultsBanner';
export const DealersListWrapper = ({ allDealersIsChecked, dealersList, inputRef, isLoadingNewDealerData, mobileNavBarState, selectedDealer, visibleIn, handleAllDealersCheck, setSelectedDealer, }) => {
    const consent = useGoogleConsent();
    const isDesktop = useDesktopOrMobileView('1024px') === 'desktop';
    const isMobileListView = !isDesktop && mobileNavBarState === 'nav-bar-location-search-mobile--list-view';
    return (React.createElement(DealersListContainer, { visible: (consent && isDesktop) || isMobileListView },
        React.createElement(DealersListHeader, { dealersList: dealersList }),
        !isLoadingNewDealerData && (dealersList === null || dealersList === void 0 ? void 0 : dealersList.length) === 0 && (React.createElement(ZeroResultsBanner, { inputRef: inputRef })),
        !isDesktop && (React.createElement(DealersListAccordionItems, { dealersList: dealersList, isLoadingNewDealerData: isLoadingNewDealerData, mobileNavBarState: mobileNavBarState, setSelectedDealer: setSelectedDealer })),
        isDesktop && (React.createElement(React.Fragment, null,
            React.createElement(DealersListItems, { dealersList: dealersList, visibleIn: visibleIn, selectedDealer: selectedDealer, setSelectedDealer: setSelectedDealer }),
            React.createElement(SelectAllDealersCheckbox, { allDealersIsChecked: allDealersIsChecked, dealersList: dealersList, visibleIn: visibleIn, handleAllDealersCheck: handleAllDealersCheck })))));
};
export default DealersListWrapper;
