import styled from 'styled-components';
import React from 'react';
import { Button } from '@audi/audi-ui-react-v2';
export const FilterNavigationContainer = styled.div `
  height: auto;
  margin-bottom: var(
    ${(props) => props.areChipsRendered
    ? props.theme.responsive.spacing.l
    : props.theme.responsive.spacing.xxxl}
  );
  display: flex;
  justify-content: center;
`;
export const FilterNavigationDiv = styled.div `
  background-color: var(${(props) => props.theme.colors.base.brand.black});
  position: ${(props) => (props.isFixed ? 'fixed' : 'static')};
  top: 0;
  z-index: 3;
  width: 100%;
  max-width: 1920px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    width: ${(props) => (props.isFixed ? 'calc(100% - 80px)' : '100%')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
    width: ${(props) => (props.isFixed ? 'calc(100% - 120px)' : '100%')};
  }
  @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
    width: ${(props) => (props.isFixed ? 'calc(100% - 192px)' : '100%')};
  }
`;
export const FilterNavigationMobile = styled.div `
  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: none;
  }
`;
export const FilterNavigationMobileButton = styled((props) => (React.createElement(Button, Object.assign({ variant: "primary", icon: "filter" }, props)))) `
  width: 100%;
`;
export const FilterNavigationDesktop = styled.div `
  display: none;
  height: 72px;

  @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
    display: flex;
    align-items: center;
    padding: 0 var(${(props) => props.theme.responsive.spacing.xxl});
  }
`;
export const FilterNavigationCategories = styled.div `
  overflow-x: auto;
  height: 100%;
`;
export const FilterHeadline = styled.div `
  display: flex;
`;
export const NavBarContainer = styled.div `
  line-height: var(${(props) => props.theme.responsive.typography.copy1.lineHeight});
  display: flex;
  height: 72px;
`;
export const NavBarScrollContainer = styled.div `
  position: relative;
  display: grid;
  overflow-x: auto;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
export const NavBarScrollControl = styled.button `
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 0;
  opacity: ${(props) => (props.displayed ? '0' : '1')};
  color: var(${(props) => props.theme.colors.interaction.secondary.default});
  background: transparent;
  border: 0;
  border-radius: 0;
  transform: ${(props) => (props.position === 'start' ? 'rotate(180deg)' : 'none')};
  cursor: ${(props) => (props.displayed ? 'unset' : 'pointer')};
  appearance: none;
  margin-left: ${(props) => (props.position === 'end' ? '4px' : '0')};

  &:hover {
    color: var(${(props) => props.theme.colors.interaction.secondary.hover});
  }

  [dir='rtl'] & {
    transform: ${(props) => (props.position === 'end' ? 'rotate(180deg)' : 'none')};
  }

  &:disabled {
    color: var(${(props) => props.theme.colors.interaction.secondary.disabled});

    cursor: not-allowed;
  }
`;
export const NavBarScrollIcon = styled.svg `
  width: 24px;
  height: 24px;
  color: currentColor;
  transition: color 0.1s var(${(props) => props.theme.easing});
  pointer-events: none;
`;
export const NavBarItem = styled.li `
  display: flex;

  @media (max-width: ${(props) => props.theme.breakpoints.m}px) {
    flex-grow: 1;
  }

  & > * {
    position: relative;
    display: inline-block;
    padding: 0px var(${(props) => props.theme.responsive.spacing.s});
    color: var(${(props) => props.theme.colors.interaction.secondary.default});
    white-space: nowrap;
    text-decoration: none;
    background: transparent;
    border: none;
    border-radius: 0;
  }

  & > *::-moz-focus-inner {
    padding: 0;
    border: 0;
  }

  & > *:hover {
    color: var(${(props) => props.theme.colors.interaction.secondary.hover});
  }

  & > *[aria-selected='true'],
  & > *[aria-current='true'] {
    color: var(${(props) => props.theme.colors.interaction.secondary.active});
  }

  & > *[aria-disabled] {
    color: var(${(props) => props.theme.colors.interaction.secondary.disabled});
    cursor: not-allowed;
  }

  & > *:focus {
    outline-width: 1px;
    outline-style: solid;
    outline-offset: -1px;
  }

  & > *:focus:not(:focus-visible) {
    outline-width: unset;
    outline-style: unset;
  }
`;
export const NavBarList = styled.ul `
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  grid-row: 1 / 4;
  grid-column: 1;
  justify-content: stretch;
  margin: 0;
  padding: 0;
  list-style: none;
`;
