import React, { useEffect, useState } from 'react';
import { Button, Text } from '@audi/audi-ui-react-v2';
import { useServicesContext } from '../../../context';
import { useI18n } from '@oneaudi/i18n-context';
import { LayerContentHTML } from '@oneaudi/fa-one-layer';
import { InfoTextContainer } from '../styles/InfoButton.styles';
import { trackClicks } from '../tracking';
import { useScsUrlParts } from '../../../hooks';
const InfoButton = ({ filterGroupIdOrFilterId, filterOrGroupText, filterCategory, }) => {
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const layerManager = useServicesContext().getAdditionalService('layer-manager');
    const [campaignMicroSiteUrl, setCampaignMicroSiteUrl] = useState(undefined);
    const { scsBaseUrl, defaultVersion, marketPath } = useScsUrlParts();
    useEffect(() => {
        if (filterGroupIdOrFilterId.startsWith('campaign.')) {
            const campaignId = filterGroupIdOrFilterId.replace('campaign.', '');
            fetch(`${scsBaseUrl}${defaultVersion}/structure/campaigns/${marketPath}?svd=1`)
                .then((response) => {
                if (!response.ok) {
                    throw new Error('Scs Campaign api threw an error');
                }
                return response.json();
            })
                .then((data) => {
                const campaignContent = data === null || data === void 0 ? void 0 : data.find((campaignEntry) => campaignEntry.campaignId === campaignId);
                if (campaignContent) {
                    setCampaignMicroSiteUrl(campaignContent.micrositeUrl);
                }
            })
                .catch((error) => {
                console.error('There was a problem with fetching campaigns:', error);
            });
        }
    }, [filterGroupIdOrFilterId]);
    const infoButtonId = `infoButton-${filterGroupIdOrFilterId}`.split('.').join('');
    const tooltipText = useI18n({
        id: `nemo.sc.filter.${filterGroupIdOrFilterId}.tooltipText`,
        defaultMessage: '',
    });
    const infoButtonariaText = useI18n({
        id: `nemo.sc.filter.infoButton.ariaText`,
        defaultMessage: 'Additional information about {{filter}}.',
    }).replace('{{filter}}', filterOrGroupText);
    const hasCompleteLayerContent = campaignMicroSiteUrl && infoButtonariaText;
    const hasCompleteTooltipText = tooltipText && infoButtonariaText;
    const openTooltipLayer = () => {
        const trackingEventName = 'vtp filter - click on info i';
        trackClicks(trackingService, trackingEventName, `${filterOrGroupText}`, 'icon', `${filterCategory}`, true);
        layerManager.openFocusLayer(() => (React.createElement(InfoTextContainer, null,
            React.createElement(Text, null, tooltipText))), {}, {
            userCloseable: true,
            size: "B",
            onClose: () => {
                setTimeout(() => document === null || document === void 0 ? void 0 : document.getElementById(infoButtonId).focus());
            },
        });
    };
    const openIFrameLayer = () => {
        const trackingEventName = 'vtp filter - click on info i';
        trackClicks(trackingService, trackingEventName, `${filterOrGroupText}`, 'icon', `${filterCategory}`, true);
        if (campaignMicroSiteUrl) {
            layerManager.openFocusLayer(() => React.createElement(LayerContentHTML, { url: campaignMicroSiteUrl }), {}, {
                userCloseable: true,
                size: "B",
                onClose: () => {
                    setTimeout(() => document === null || document === void 0 ? void 0 : document.getElementById(infoButtonId).focus());
                },
            });
        }
    };
    const getButtonWithIFrameLayer = () => (React.createElement(Button, { spaceInlineStart: "s", variant: "text", onClick: openIFrameLayer, "data-testid": "filterInfoLayerIFrame", icon: "system-info", key: `infobutton-${filterGroupIdOrFilterId}`, "aria-label": infoButtonariaText, id: infoButtonId }));
    const getButtonWithTooltipLayer = () => (React.createElement(Button, { spaceInlineStart: "s", variant: "text", onClick: openTooltipLayer, "data-testid": "filterInfoLayerTooltip", icon: "system-info", key: `infobutton-${filterGroupIdOrFilterId}`, "aria-label": infoButtonariaText, id: infoButtonId }));
    if (hasCompleteLayerContent) {
        return getButtonWithIFrameLayer();
    }
    if (hasCompleteTooltipText) {
        return getButtonWithTooltipLayer();
    }
    return null;
};
export default InfoButton;
