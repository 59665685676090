import React from 'react';
import { FeatureAppDefinition, FeatureAppEnvironment, FeatureServices } from '@feature-hub/core';
import { ReactFeatureApp } from '@feature-hub/react';
import type { Logger } from '@feature-hub/logger';
import { ApolloClient, OneGraphProvider } from '@oneaudi/onegraph-client';
import I18nContextComponent from '@oneaudi/i18n-context';
import { audiDarkTheme, ThemeProvider } from '@audi/audi-ui-react-v2';
import { Provider } from 'react-redux';
import {
  AudiStockcarsStoreServiceV1,
  defineAudiStockcarsStoreService,
} from '@oneaudi/stockcars-store-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@oneaudi/footnote-reference-service';
import { I18NServiceV1 } from '@oneaudi/i18n-service';
import { GfaLayerManagerV1 } from '@volkswagen-onehub/gfa-layer-manager';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { AudiFootnoteServiceInterfaceV1 } from '@oneaudi/footnote-service';
import { Store } from 'redux';
import { ContentService } from '@oneaudi/content-service';
import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { PageInfoServiceV1 } from '@oneaudi/page-info-service';
import {
  FootnoteReferenceContextProvider,
  FootnoteServiceContextProvider,
  initializeFeatureApp,
  ServicesContextProvider,
  FilterFeatureAppConfig,
  SeoFilterValue,
  createFilterSeoResolver,
} from '@oneaudi/vtp-shared';
import { AsyncSsrManagerV1 } from '@feature-hub/async-ssr-manager';
import { SerializedStateManagerV1 } from '@feature-hub/serialized-state-manager';
import { ServerRequestV1 } from '@feature-hub/server-request';
import { LayerManagerV25 } from '@volkswagen-onehub/layer-manager';
import { OneGraphServiceV1 } from '@oneaudi/onegraph-service';
import { EnvConfigServiceV1 } from '@oneaudi/audi-env-config-service';
import {
  ConfigurationServiceV1,
  defineConfigurationService,
} from '@oneaudi/vtp-configuration-service';
import App from './FeatureApp';

interface Dependencies extends FeatureServices {
  readonly 's2:logger'?: Logger;
  readonly 's2:async-ssr-manager': AsyncSsrManagerV1 | undefined;
  readonly 's2:server-request': ServerRequestV1 | undefined;
  readonly 's2:serialized-state-manager': SerializedStateManagerV1 | undefined;
  readonly 'audi-stockcars-store-service': AudiStockcarsStoreServiceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'audi-footnote-service': AudiFootnoteServiceInterfaceV1;
  readonly 'gfa:layer-manager': GfaLayerManagerV1;
  readonly 'gfa:locale-service': LocaleServiceV1;
  readonly 'layer-manager': LayerManagerV25;
  readonly 'audi-content-service': ContentService;
  readonly 'audi-tracking-service': TrackingServiceV2;
  readonly 'onegraph-service': OneGraphServiceV1;
  readonly 'vtp-configuration-service': ConfigurationServiceV1;
  readonly 'audi:envConfigService': EnvConfigServiceV1;
  readonly 'page-info-service': PageInfoServiceV1;
}

type AdditionalData = {
  seoFilters: SeoFilterValue[];
};

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies> = {
  dependencies: {
    featureServices: {
      's2:serialized-state-manager': '^1.0.0',
      'audi-stockcars-store-service': '1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'gfa:locale-service': '1.0.0',
      'layer-manager': '^2.5.0',
      'audi-footnote-reference-service': '^3.0.0',
      'audi-content-service': '^1.0.0',
      'onegraph-service': '^1.0.0',
      'vtp-configuration-service': '^1.0.0',
      'audi:envConfigService': '1.0.0',
      'gfa:service-config-provider': '1.0.0',
    },
    externals: {
      react: '^17.0.2 || ^18.2.0',
      'styled-components': '*',
      '@audi/audi-ui-react-v2': '*',
      '@oneaudi/onegraph-client': '*',
    },
  },

  optionalDependencies: {
    featureServices: {
      's2:logger': '^1.0.0',
      's2:async-ssr-manager': '^1.0.0',
      's2:server-request': '^1.0.0',
      's2:serialized-state-manager': '^1.0.0',
      'audi-tracking-service': '^2.0.0',
      'audi-footnote-service': '1.0.0',
      'page-info-service': '1.0.0',
      'gfa:layer-manager': '1.0.0',
    },
  },

  ownFeatureServiceDefinitions: [defineAudiStockcarsStoreService(), defineConfigurationService()],

  create: ({
    featureServices,
    config = {},
    baseUrl,
  }: FeatureAppEnvironment<Dependencies, FilterFeatureAppConfig>) => {
    const stockcarsStoreService = featureServices['audi-stockcars-store-service'];
    const pageInfoService = featureServices['page-info-service'];
    const serverRequest = featureServices['s2:server-request'];
    const configurationService = featureServices['vtp-configuration-service'];
    const envConfigService = featureServices['audi:envConfigService'];
    const i18nService = featureServices['dbad:audi-i18n-service'];
    const localeService = featureServices['gfa:locale-service'];

    // do not use destructuring here, as this breaks the ssr build
    const initialization = initializeFeatureApp<
      Dependencies,
      FilterFeatureAppConfig,
      AdditionalData
    >(featureServices, config, {
      seoFilters: createFilterSeoResolver(
        configurationService,
        envConfigService,
        serverRequest,
        pageInfoService,
        i18nService,
        localeService
      ),
    });

    if (!config.assetUrl) {
      // eslint-disable-next-line
      config.assetUrl = `${baseUrl || ''}/static`;
    } else {
      // eslint-disable-next-line
      config.assetUrl = config.assetUrl.endsWith('/')
        ? config.assetUrl.slice(0, -1)
        : config.assetUrl;
    }

    const oneGraphClient: ApolloClient<unknown> =
      featureServices['onegraph-service'].getApolloClient();

    return {
      loadingPromise: initialization.loadingPromise?.featureAppInitializationPromise,
      render: () => (
        <ThemeProvider theme={audiDarkTheme}>
          <Provider store={stockcarsStoreService.store.store as unknown as Store}>
            <ServicesContextProvider
              audiStockcarsStoreService={stockcarsStoreService}
              contextId={initialization.contextId!}
              featureAppConfig={config}
              additionalServices={featureServices}
              envConfig={initialization.envConfig}
            >
              <I18nContextComponent
                i18nData={featureServices['dbad:audi-i18n-service']}
                language={featureServices['gfa:locale-service'].language}
                // @ts-ignore
                featureServices={featureServices}
                scopes={[]}
              >
                <FootnoteServiceContextProvider
                  footnoteService={featureServices['audi-footnote-service']}
                >
                  <FootnoteReferenceContextProvider
                    footnoteReferenceServiceScopeManager={
                      featureServices['audi-footnote-reference-service']
                    }
                  >
                    <OneGraphProvider client={oneGraphClient as ApolloClient<unknown>}>
                      {initialization.loadingPromise?.state.initialized &&
                        initialization.initializationType !== 'ssr' && (
                          <App seoFilters={initialization.additionalData.seoFilters} />
                        )}
                    </OneGraphProvider>
                  </FootnoteReferenceContextProvider>
                </FootnoteServiceContextProvider>
              </I18nContextComponent>
            </ServicesContextProvider>
          </Provider>
        </ThemeProvider>
      ),
    };
  },
};

export default featureAppDefinition;
