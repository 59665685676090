import React, { useEffect, useState } from 'react';
import { StyledAddressIcon, StyledAddressTextFullWidth, StyledAddressTextNextToIcon, AddressMobile, DealersListAccordionWrapper, StyledPagination, StyledAccordion, } from '../styles/LocationFilter.styles';
import { useFilterData } from '../FilterContextHooks';
import { FilterOverlayAccordion } from './FilterOverlayAccordion';
import { FilterOverlayAccordionSection } from './FilterOverlayAccordionSection';
import { useDesktopOrMobileView } from '../../../hooks';
import { getAllCountersForMatches } from '../utils/utils';
import { useI18n } from '@oneaudi/i18n-context';
export const DealersListAccordionItems = ({ dealersList, isLoadingNewDealerData, mobileNavBarState, setSelectedDealer, }) => {
    const filterData = useFilterData();
    const isMobileScreenSize = useDesktopOrMobileView('767px') === 'mobile';
    const isDesktopScreenSize = useDesktopOrMobileView('1024px') === 'desktop';
    const isScreenSizeM = !isDesktopScreenSize && !isMobileScreenSize;
    const dealersPerPage = isMobileScreenSize ? 5 : 9;
    const [currentSlideIndex, setSlideIndex] = useState(0);
    const [isNewPage, setIsNewPage] = useState(true);
    const ariaLabelForAccordionButton = useI18n({
        id: `nemo.sc.filter.locationfilter.ariaLabelForAccordionButton`,
        defaultMessage: 'Displays the address of dealer',
    });
    const [noMatchesFoundCounter, singleMatchFoundCounter, multipleMatchesFoundCounter] = getAllCountersForMatches();
    const getI18nCounterForThisNumberOfMatches = (numberOfMatches) => {
        const counterForThisNumberOfMatches = numberOfMatches === 0
            ? noMatchesFoundCounter
            : numberOfMatches === 1
                ? singleMatchFoundCounter
                : multipleMatchesFoundCounter;
        return counterForThisNumberOfMatches;
    };
    useEffect(() => {
        setIsNewPage(true);
    }, [currentSlideIndex]);
    useEffect(() => {
        if (isLoadingNewDealerData) {
            setSlideIndex(0);
        }
    }, [isLoadingNewDealerData]);
    useEffect(() => {
        if (dealersList && mobileNavBarState === 'nav-bar-location-search-mobile--list-view') {
            const focusedDealer = dealersList.find((item) => {
                return item.status === 'focused';
            });
            if (focusedDealer) {
                const focusedDealerIndex = dealersList.findIndex((item) => item.id === focusedDealer.id);
                setSlideIndex(Math.ceil((focusedDealerIndex + 1) / dealersPerPage) - 1);
            }
            else {
                setSlideIndex(0);
            }
            setIsNewPage(true);
        }
        else if (mobileNavBarState === 'nav-bar-location-search-mobile--map-view') {
            setIsNewPage(false);
            setSlideIndex(undefined);
        }
    }, [mobileNavBarState]);
    const renderSlicedDealersList = () => {
        const renderedDealers = dealersList === null || dealersList === void 0 ? void 0 : dealersList.slice(currentSlideIndex * dealersPerPage, (currentSlideIndex + 1) * dealersPerPage);
        return (React.createElement(FilterOverlayAccordion, { className: "accordiondealerslist", multiple: true }, renderedDealers === null || renderedDealers === void 0 ? void 0 : renderedDealers.map((result) => {
            var _a, _b;
            const dealersFilterData = (_b = (_a = filterData === null || filterData === void 0 ? void 0 : filterData.groups) === null || _a === void 0 ? void 0 : _a.dealers) === null || _b === void 0 ? void 0 : _b.members;
            const dealerDataFromFilterEndpoint = dealersFilterData[`dealer.${result.id}`] || {
                count: 0,
                active: false,
            };
            const dealerIsActive = dealerDataFromFilterEndpoint.active;
            const dealerCount = dealerDataFromFilterEndpoint.count;
            const i18nCounterForThisNumberOfMatches = ` ${getI18nCounterForThisNumberOfMatches(dealerCount)}`;
            const label = `${result.name} (${dealerCount}${isScreenSizeM ? i18nCounterForThisNumberOfMatches : ''})`;
            const ariaLabelForAccordionButtonForThisDealer = `${ariaLabelForAccordionButton} ${result.name}`;
            const dealersListAccordionItemProps = {
                isCheckedControlledByClickOnMarker: result.status === 'focused' || result.status === 'checked',
                active: dealerIsActive,
                dealerStatus: result.status,
                setSelectedDealer,
                count: dealerCount,
                ariaLabelForAccordionButton: ariaLabelForAccordionButtonForThisDealer,
            };
            return (React.createElement(FilterOverlayAccordionSection, { key: `dealer-${result.id}`, id: `dealer.${result.id}`, label: label, isUsedInLocationFilter: true, dealerAccordionItemProps: dealersListAccordionItemProps, isDisplayingFocusedDealer: result.status === 'focused' },
                React.createElement(AddressMobile, { status: result.status },
                    React.createElement(StyledAddressIcon, { disabled: dealerCount === 0 }),
                    React.createElement(StyledAddressTextNextToIcon, { disabled: dealerCount === 0 },
                        result.street,
                        ","),
                    React.createElement(StyledAddressTextFullWidth, { disabled: dealerCount === 0 },
                        result.region,
                        " ",
                        result.city))));
        })));
    };
    const onChangeHandler = (slide) => {
        setIsNewPage(false);
        setSlideIndex(slide - 1);
    };
    return (React.createElement(DealersListAccordionWrapper, null,
        React.createElement(StyledAccordion, null, isNewPage && renderSlicedDealersList()),
        dealersList && dealersList.length > 0 && (React.createElement(StyledPagination, { count: Math.ceil(dealersList.length / dealersPerPage), onChange: (slide) => onChangeHandler(slide), page: currentSlideIndex !== undefined && currentSlideIndex + 1, variant: "compact" }))));
};
export default DealersListAccordionItems;
