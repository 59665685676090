import React, { useState } from 'react';
import { StyledImagePlaceholder, StyledImg } from '../styles/ModelFilter.styles';
import { Text, Icon } from '@audi/audi-ui-react-v2';
import { VisuallyHidden } from '../utils/utils';
import { useDesktopOrMobileView } from '../../../hooks';
export const ModelImage = ({ carlineGroup, carlineGroupPhoto, carlineGroupPhotoNotAvailableText, open, }) => {
    const isAtLeastMBreakpoint = useDesktopOrMobileView('768px') === 'desktop';
    const isAtLeastLBreakpoint = useDesktopOrMobileView('1024px') === 'desktop';
    const isAtLeastXLBreakpoint = useDesktopOrMobileView('1440px') === 'desktop';
    let textSize = 'copy2';
    let iconSize = 'small';
    if ((isAtLeastMBreakpoint && !isAtLeastLBreakpoint) || isAtLeastXLBreakpoint) {
        textSize = 'copy1';
        iconSize = 'large';
    }
    const [imgIsBroken, setImgIsBroken] = useState(false);
    const handleError = () => {
        setImgIsBroken(true);
    };
    if (!carlineGroupPhoto) {
        return (React.createElement(StyledImagePlaceholder, { open: open },
            React.createElement(Icon, { name: "photo-none", size: iconSize, variant: "primary", spaceStackEnd: "xs" }),
            React.createElement(VisuallyHidden, null, carlineGroup),
            React.createElement(Text, { as: "p", variant: textSize }, carlineGroupPhotoNotAvailableText)));
    }
    if (imgIsBroken) {
        return (React.createElement(StyledImagePlaceholder, { open: open },
            React.createElement(Icon, { name: "photo-none", size: iconSize, variant: "primary", spaceStackEnd: "xs" }),
            React.createElement(VisuallyHidden, null, carlineGroup),
            React.createElement(Text, { as: "p", variant: textSize }, carlineGroupPhotoNotAvailableText)));
    }
    return React.createElement(StyledImg, { alt: `${carlineGroup}`, src: `${carlineGroupPhoto}`, onError: handleError });
};
export default ModelImage;
