export var MarketType;
(function (MarketType) {
    MarketType["UC"] = "UC";
    MarketType["NC"] = "NC";
})(MarketType || (MarketType = {}));
export const MetaInfoData = {
    META_TITLE_UC: {
        type: 'title',
        maxLength: 58,
        suffixCount: 4,
        prefix: 'nemo.sc.meta.title.',
        suffix: 'nemo.sc.meta.title.uc.suffix.',
        suffixDelimiter: ' ',
    },
    META_DESC_UC: {
        type: 'description',
        maxLength: 158,
        suffixCount: 6,
        prefix: 'nemo.sc.meta.desc.',
        suffix: 'nemo.sc.meta.desc.uc.suffix.',
        suffixDelimiter: ': ',
    },
    META_TITLE_NC: {
        type: 'title',
        maxLength: 58,
        suffixCount: 4,
        prefix: 'nemo.sc.meta.title.',
        suffix: 'nemo.sc.meta.title.nc.suffix.',
        suffixDelimiter: ' ',
    },
    META_DESC_NC: {
        type: 'description',
        maxLength: 158,
        suffixCount: 6,
        prefix: 'nemo.sc.meta.desc.',
        suffix: 'nemo.sc.meta.desc.nc.suffix.',
        suffixDelimiter: ': ',
    },
    getTitle(marketType) {
        return MetaInfoData[`META_TITLE_${marketType}`] || MetaInfoData.META_TITLE_NC;
    },
    getDescription(marketType) {
        return MetaInfoData[`META_DESC_${marketType}`] || MetaInfoData.META_DESC_NC;
    },
};
