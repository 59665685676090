import React, { useState, useEffect } from 'react';
import { FieldsetOneCol, FieldsetWrapper, GroupHeading, VisuallyHiddenLegend, MoreOptionsButtonsWrapper, } from '../styles/CheckboxFilter.styles';
import { CheckboxSimple } from './CheckboxSimple';
import { Text, Button } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { CheckboxColorTile } from './CheckboxColorTile';
import InfoButton from './InfoButton';
import { useBreakpoint } from '../hooks/useBreakpoint';
export const CheckboxFilter = ({ isCarTypeIconCheckbox, isColorTypeCheckbox, filterCategory, group, layoutWidth, isPartOfEquipmentFilter, visibleIn, headingLevel = 'h4', isPlpQuickFilters, }) => {
    const { groupHeadline, groupText, items } = group;
    if (items.length < 1) {
        return null;
    }
    if (groupHeadline === 'ranges') {
        return null;
    }
    const filterGroupI18nLabel = useI18n({
        id: `nemo.sc.filter.filtergroups.${groupHeadline}`,
        defaultMessage: '',
    });
    const showMoreOptionsLabel = useI18n({
        id: `nemo.sc.plp.quickfilters.showmoreoptions`,
        defaultMessage: 'Show more options',
    });
    const hideOptionsLabel = useI18n({
        id: `nemo.sc.plp.quickfilters.hideoptions`,
        defaultMessage: 'Hide options',
    });
    const breakpoint = useBreakpoint();
    const [showAllCheckboxesInQuickFilters, setShowAllCheckboxesInQuickFilters] = useState(false);
    const [maxNumberOfCarTypeIconCheckboxesToDisplay, setMaxNumberOfCarTypeIconCheckboxesToDisplay] = useState(0);
    const [maxNumberOfColorTypeCheckboxesToDisplay, setMaxNumberOfColorTypeCheckboxesToDisplay] = useState(0);
    const [maxNumberOfCheckboxesToDisplay, setMaxNumberOfCheckboxesToDisplay] = useState(0);
    const maxNumberOfCheckboxes = isCarTypeIconCheckbox
        ? maxNumberOfCarTypeIconCheckboxesToDisplay
        : isColorTypeCheckbox
            ? maxNumberOfColorTypeCheckboxesToDisplay
            : maxNumberOfCheckboxesToDisplay;
    const numberOfAdditionalCheckboxes = items.length - maxNumberOfCheckboxes;
    const filterGroupLabel = filterGroupI18nLabel || groupText;
    useEffect(() => {
        switch (breakpoint) {
            case 'XS':
                setMaxNumberOfCarTypeIconCheckboxesToDisplay(2);
                setMaxNumberOfColorTypeCheckboxesToDisplay(4);
                setMaxNumberOfCheckboxesToDisplay(3);
                break;
            case 'S':
                setMaxNumberOfCarTypeIconCheckboxesToDisplay(2);
                setMaxNumberOfColorTypeCheckboxesToDisplay(5);
                setMaxNumberOfCheckboxesToDisplay(3);
                break;
            case 'M':
                setMaxNumberOfCarTypeIconCheckboxesToDisplay(4);
                setMaxNumberOfColorTypeCheckboxesToDisplay(4);
                setMaxNumberOfCheckboxesToDisplay(6);
                break;
            case 'L':
                setMaxNumberOfCarTypeIconCheckboxesToDisplay(2);
                setMaxNumberOfColorTypeCheckboxesToDisplay(3);
                setMaxNumberOfCheckboxesToDisplay(3);
                break;
            case 'XL':
                setMaxNumberOfCarTypeIconCheckboxesToDisplay(2);
                setMaxNumberOfColorTypeCheckboxesToDisplay(4);
                setMaxNumberOfCheckboxesToDisplay(3);
                break;
            case 'XXL':
                setMaxNumberOfCarTypeIconCheckboxesToDisplay(2);
                setMaxNumberOfColorTypeCheckboxesToDisplay(4);
                setMaxNumberOfCheckboxesToDisplay(3);
                break;
        }
    }, [breakpoint]);
    const handleShowMoreCheckboxes = () => {
        setShowAllCheckboxesInQuickFilters(true);
    };
    const handleShowFewerCheckboxes = () => {
        setShowAllCheckboxesInQuickFilters(false);
    };
    const getColorParameter = (colorDetails) => {
        const itemColor = colorDetails.slice(colorDetails.indexOf('.') + 1);
        if (colorDetails.includes('exterior')) {
            return { colorType: 'exterior', colorName: itemColor };
        }
        return { colorType: 'interior', colorName: itemColor };
    };
    return (React.createElement(FieldsetWrapper, { layoutWidth: layoutWidth, isColorTypeCheckbox: isColorTypeCheckbox },
        React.createElement(GroupHeading, null,
            React.createElement(Text, { as: headingLevel, variant: "order3" }, filterGroupLabel),
            isColorTypeCheckbox && (React.createElement(InfoButton, { filterGroupIdOrFilterId: groupHeadline, filterOrGroupText: filterGroupLabel, filterCategory: filterCategory }))),
        React.createElement(FieldsetOneCol, { key: groupHeadline, "$layoutWidth": layoutWidth, "$isColorTypeCheckbox": isColorTypeCheckbox, "$isPartOfEquipmentFilter": isPartOfEquipmentFilter, "$isPlpQuickFilters": isPlpQuickFilters },
            React.createElement(VisuallyHiddenLegend, null, filterGroupLabel),
            items.map((item, index) => {
                if (isPlpQuickFilters) {
                    if (isColorTypeCheckbox &&
                        (index < maxNumberOfCheckboxes || showAllCheckboxesInQuickFilters)) {
                        const color = getColorParameter(item.itemHeadline);
                        return (React.createElement(CheckboxColorTile, { filterCategory: filterCategory, visibleIn: visibleIn, itemHeadline: item.itemHeadline, itemText: item.itemText, groupHeadline: groupHeadline, key: item.itemHeadline, color: color, isPlpQuickFilters: isPlpQuickFilters }));
                    }
                    if (index < maxNumberOfCheckboxes || showAllCheckboxesInQuickFilters) {
                        return (React.createElement(CheckboxSimple, { groupHeadline: groupHeadline, filterCategory: filterCategory, visibleIn: visibleIn, isCarTypeIconCheckbox: isCarTypeIconCheckbox, itemHeadline: item.itemHeadline, itemText: item.itemText, key: item.itemHeadline }));
                    }
                }
                else {
                    if (isColorTypeCheckbox) {
                        const color = getColorParameter(item.itemHeadline);
                        return (React.createElement(CheckboxColorTile, { filterCategory: filterCategory, visibleIn: visibleIn, itemHeadline: item.itemHeadline, itemText: item.itemText, groupHeadline: groupHeadline, key: item.itemHeadline, color: color, isPlpQuickFilters: isPlpQuickFilters }));
                    }
                    return (React.createElement(CheckboxSimple, { groupHeadline: groupHeadline, filterCategory: filterCategory, visibleIn: visibleIn, isCarTypeIconCheckbox: isCarTypeIconCheckbox, itemHeadline: item.itemHeadline, itemText: item.itemText, key: item.itemHeadline }));
                }
                return '';
            })),
        React.createElement(MoreOptionsButtonsWrapper, null,
            isPlpQuickFilters &&
                numberOfAdditionalCheckboxes > 0 &&
                !showAllCheckboxesInQuickFilters && (React.createElement(Button, { variant: "text", icon: "system-add", size: "small", onClick: handleShowMoreCheckboxes }, showMoreOptionsLabel)),
            isPlpQuickFilters &&
                numberOfAdditionalCheckboxes > 0 &&
                showAllCheckboxesInQuickFilters && (React.createElement(Button, { variant: "text", icon: "system-remove", size: "small", onClick: handleShowFewerCheckboxes }, hideOptionsLabel)))));
};
export default CheckboxFilter;
