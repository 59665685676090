export const useI18nBulk = (i18nService, localeService, messages) => {
    const getTranslation = (arg) => {
        var _a;
        const { id, defaultMessage, placeholder } = arg;
        const translated = getTranslationFromService(id, localeService, i18nService);
        if (placeholder) {
            const message = [...Object.entries(placeholder)].reduce((replacedMessage, [currentPlaceholder, currentValue]) => replacedMessage === null || replacedMessage === void 0 ? void 0 : replacedMessage.replace(`{{${currentPlaceholder}}}`, `${currentValue}`), translated !== null && translated !== void 0 ? translated : defaultMessage);
            return message !== null && message !== void 0 ? message : `missing translation: ${id}`;
        }
        return (_a = translated !== null && translated !== void 0 ? translated : defaultMessage) !== null && _a !== void 0 ? _a : `missing translation: ${id}`;
    };
    return Object.keys(messages).reduce(function (result, key) {
        result[key] = getTranslation(messages[key]);
        return result;
    }, {});
};
const getTranslationFromService = (id, localeService, i18nService) => {
    var _a, _b;
    const data = i18nService === null || i18nService === void 0 ? void 0 : i18nService.getMessages();
    const language = (_a = localeService === null || localeService === void 0 ? void 0 : localeService.language) !== null && _a !== void 0 ? _a : 'default';
    return (_b = data === null || data === void 0 ? void 0 : data[language]) === null || _b === void 0 ? void 0 : _b[id];
};
