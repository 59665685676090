import { MetaInfoData } from './seo.types';
import { messagesForMarket } from './messages';
import { useI18nBulk } from './i18n';
export const handleSeo = (pageInfoService, i18nService, localeService, marketType, filters) => {
    const seoData = resolveSeoData(i18nService, localeService, marketType, filters);
    if (seoData) {
        pageInfoService.setPageInfo({
            seo: seoData,
        });
    }
};
export const resolveSeoData = (i18nService, localeService, marketType, filters) => {
    if (!filters.length) {
        return null;
    }
    const normalizedFilters = normalizeFilters(filters);
    const messages = messagesForMarket(marketType, normalizedFilters);
    const translations = useI18nBulk(i18nService, localeService, messages);
    const title = buildTitle(MetaInfoData.getTitle(marketType), normalizedFilters, translations);
    const description = buildDescription(MetaInfoData.getDescription(marketType), normalizedFilters, translations);
    return {
        pageTitle: title,
        description,
        og: {
            ogTitle: title,
            ogDescription: description,
        },
    };
};
export const normalizeFilters = (filters) => ({
    carline: resolveFilterValue(filters, 'carline.'),
    drive: resolveFilterValue(filters, 'drive.'),
    exteriorColor: resolveFilterValue(filters, 'exterior-color.'),
    fuelType: resolveFilterValue(filters, 'fuel-type.'),
    gearType: resolveFilterValue(filters, 'gear-type.'),
});
const resolveFilterValue = (filters, filterPrefix) => {
    var _a;
    return ((_a = filters.find((filter) => filter.filter.startsWith(filterPrefix))) === null || _a === void 0 ? void 0 : _a.value) || '';
};
const buildTitle = (metaInfoData, filters, translations) => {
    let metaString = concatToMaxLength(metaInfoData.maxLength, ...[
        filters.carline !== '' && translations.titleCarline,
        filters.fuelType !== '' && translations.titleFuelType,
        filters.gearType !== '' && translations.titleGearType,
        filters.drive !== '' && translations.titleDrive,
        filters.exteriorColor !== '' && translations.titleExteriorColor,
    ].filter((prefix) => Boolean(prefix)));
    metaString = concatMaxLengthSuffix(metaInfoData.maxLength, metaInfoData.suffixDelimiter, metaString, translations.titleSuffix1, translations.titleSuffix2, translations.titleSuffix3, translations.titleSuffix4);
    return metaString;
};
const buildDescription = (metaInfoData, filters, translations) => {
    let metaString = concatToMaxLength(metaInfoData.maxLength, ...[
        translations.descCarline,
        filters.fuelType !== '' && translations.descFuelType,
        filters.gearType !== '' && translations.descGearType,
        filters.drive !== '' && translations.descDrive,
        filters.exteriorColor !== '' && translations.descExteriorColor,
    ].filter((prefix) => Boolean(prefix)));
    metaString = concatMaxLengthSuffix(metaInfoData.maxLength, metaInfoData.suffixDelimiter, metaString, translations.descSuffix1, translations.descSuffix2, translations.descSuffix3, translations.descSuffix4, translations.descSuffix5, translations.descSuffix6);
    return metaString;
};
const concatToMaxLength = (maxLength, ...strings) => {
    return strings.reduce((prev, current, index) => {
        const currentTrimmed = current.trim();
        const delimiter = currentTrimmed.startsWith(', ') || index === 0 ? '' : ' ';
        if (prev.length + currentTrimmed.length + delimiter.length <= maxLength) {
            return [prev, currentTrimmed].join(delimiter);
        }
        return prev;
    }, '');
};
const concatMaxLengthSuffix = (maxLength, delimiter, prefix, ...suffixes) => {
    const maxLengthSuffix = suffixes
        .reverse()
        .find((suffix) => prefix.length + suffix.length + delimiter.length <= maxLength);
    if (maxLengthSuffix) {
        return [prefix, maxLengthSuffix].join(delimiter);
    }
    return prefix;
};
