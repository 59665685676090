import React, { useCallback, useRef, useEffect } from 'react';
import { Text } from '@audi/audi-ui-react-v2';
import { AccordionSectionPanel, AccordionSectionHeaderButton, AccordionSectionHeaderButtonContent, AccordionSectionPanelContent, FilterAccordionSectionContent, StyledIcon, } from '../styles/FilterOverlay.styles';
import { noop, setPanelHeight, setHeaderButtonPosition } from '../utils/utils';
import { useServicesContext } from '../../../context';
import { trackClicks } from '../tracking';
import { DealerAccordionItemHeader } from './DealerAccordionItemHeader';
import { FilterContext } from '../FilterContext';
export const FilterOverlayAccordionSection = ({ children, id, label, defaultOpen = false, open = defaultOpen, toggleRef, onToggle = noop, onKeyDown = noop, isUsedInEquipmentFilter, isUsedInEquipmentFilterOnDesktop, isUsedInLocationFilter, isDisplayingFocusedDealer, isDisplayingFocusedDealerBellowMap, accordionSectionHeadingLevel, dealerAccordionItemProps, configFilterCategory, }) => {
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    let { lightMode } = audiContentService.getContent().fields;
    const context = React.useContext(FilterContext);
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const panelId = `${id}__panel`;
    const panelRef = useRef(null);
    const contentRef = useRef(null);
    const sectionRef = useRef(null);
    const handleToggle = useCallback(() => {
        onToggle(!open);
        const valueToTrack = isUsedInEquipmentFilter ? configFilterCategory : label.split('(')[0];
        const trackingEventName = open
            ? 'vtp filter - click on category - close accordion'
            : 'vtp filter - click on category - open accordion';
        trackClicks(trackingService, trackingEventName, label.split('(')[0], 'text link', valueToTrack, false);
    }, [open, onToggle]);
    useEffect(() => {
        if (contentRef.current && panelRef.current) {
            setPanelHeight(panelRef.current, `${contentRef.current.scrollHeight}px`);
            if (typeof window !== 'undefined') {
                window.requestAnimationFrame(() => {
                    if (panelRef.current && contentRef.current) {
                        setPanelHeight(panelRef.current, open ? `${contentRef.current.scrollHeight}px` : '0px', true);
                    }
                    if (sectionRef.current &&
                        open &&
                        !isUsedInEquipmentFilterOnDesktop &&
                        !isUsedInLocationFilter) {
                        setHeaderButtonPosition(sectionRef.current, 'sticky', true);
                    }
                });
            }
        }
    }, [open]);
    const handlePanelTransitionEnd = useCallback((event) => {
        if (open && panelRef.current && panelRef.current === event.target) {
            setPanelHeight(panelRef.current, 'auto', true);
        }
        if (!open && sectionRef.current && sectionRef.current) {
            setHeaderButtonPosition(sectionRef.current, 'static', true);
        }
    }, [open]);
    const accordionSectionPanelProps = {
        ref: panelRef,
        open,
        defaultOpen,
        id: panelId,
        onTransitionEnd: handlePanelTransitionEnd,
        isUsedInEquipmentFilter,
        isUsedInLocationFilter,
        isDisplayingFocusedDealer,
        dealerAccordionItemProps,
        lightMode,
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(FilterAccordionSectionContent, { ref: sectionRef, isUsedInEquipmentFilter: isUsedInEquipmentFilter, isUsedInLocationFilter: isUsedInLocationFilter, lightMode: lightMode },
            !isUsedInLocationFilter && (React.createElement(AccordionSectionHeaderButton, { id: id, "aria-controls": panelId, "aria-expanded": open, onClick: handleToggle, onKeyDown: onKeyDown, ref: toggleRef, lightMode: lightMode },
                React.createElement(AccordionSectionHeaderButtonContent, null,
                    React.createElement(Text, { as: accordionSectionHeadingLevel || 'h3', variant: "copy1" }, label),
                    React.createElement(StyledIcon, { open: open })))),
            isUsedInLocationFilter && dealerAccordionItemProps && (React.createElement(DealerAccordionItemHeader, Object.assign({}, dealerAccordionItemProps, { isDisplayingFocusedDealerBellowMap: isDisplayingFocusedDealerBellowMap, id: id, panelId: panelId, label: label, open: open, handleToggle: handleToggle })))),
        React.createElement(AccordionSectionPanel, Object.assign({}, accordionSectionPanelProps),
            React.createElement(AccordionSectionPanelContent, { ref: contentRef, open: open, isUsedInLocationFilter: isUsedInLocationFilter }, children))));
};
