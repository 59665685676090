import React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { CookieConsentRequestContainer, CookieConsentRequestContent, } from '../styles/LocationFilter.styles';
import { Text, Button, Icon } from '@audi/audi-ui-react-v2';
export const CookieConsentRequest = ({ mapConsentButtonText, openConsentOverlay, setIsTwoClickOverlayOpened, }) => {
    const requestHeadline = useI18n({
        id: `nemo.sc.filter.locationfilter.cookieConsentRequestDealerList.Headline`,
        defaultMessage: '',
    });
    const requestContent = useI18n({
        id: `nemo.sc.filter.locationfilter.cookieConsentRequestDealerList.Content`,
        defaultMessage: '',
    });
    return (React.createElement(CookieConsentRequestContainer, null,
        React.createElement(Icon, { name: "system-info", size: "small", variant: "primary", spaceStackStart: "xxs" }),
        React.createElement(CookieConsentRequestContent, null,
            React.createElement(Text, { as: "h2", variant: "order3" }, requestHeadline),
            React.createElement(Text, { as: "p", variant: "copy1" }, requestContent),
            React.createElement(Button, { variant: "primary", size: "small", onClick: () => {
                    openConsentOverlay();
                    setIsTwoClickOverlayOpened(true);
                } }, mapConsentButtonText))));
};
export default CookieConsentRequest;
