import { gql } from '@oneaudi/onegraph-client';
export const CarlinesQuery = gql `
  query Carlines($carlineStructureIdentifier: CarlineStructureIdentifierInput!) {
    carlineStructure(identifier: $carlineStructureIdentifier) {
      carlineGroups {
        id
        name
        carlines {
          name
          modelYear
          bodyType {
            name
          }
          vehicleType
          exteriorViews {
            side
          }
        }
      }
    }
  }
`;
