import React from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { CookieConsentControlContainer } from '../styles/LocationFilter.styles';
import { Text, Toggle, Icon } from '@audi/audi-ui-react-v2';
import { saveConsentToStorage, useGoogleConsent } from '@oneaudi/two-click';
export const CookieConsentControl = ({ setMap }) => {
    const consent = useGoogleConsent();
    const contentText = useI18n({
        id: `nemo.sc.filter.locationfilter.cookieConsentControl.Content`,
        defaultMessage: '',
    });
    const toggleText = useI18n({
        id: `nemo.sc.filter.locationfilter.cookieConsentControl.Toggle`,
        defaultMessage: '',
    });
    return (React.createElement(CookieConsentControlContainer, null,
        React.createElement(Icon, { name: "system-info", size: "small", variant: "primary" }),
        React.createElement("div", null,
            React.createElement(Text, { as: "p", variant: "copy2" }, contentText),
            React.createElement(Toggle, { inputId: "toggle__with-labels", spaceStackStart: "m", on: consent, onChange: () => {
                    setMap(undefined);
                    saveConsentToStorage(false);
                } }, toggleText))));
};
export default CookieConsentControl;
