import React, { useCallback, useEffect, useRef } from 'react';
import { NavBarList, NavBarItem, NavBarScrollContainer, NavBarScrollControl, NavBarScrollIcon, NavBarContainer, } from '../styles/FilterNavigation.styles';
import FilterNavigationItem from './FilterNavigationItem';
import useScroll from '../hooks/useScroll';
const scrollMultiplier = 0.75;
const FilterNavigationBar = ({ children, id, selected, onSelect, }) => {
    const listRef = useRef(null);
    const selectedItemRef = useRef(null);
    const isItem = useCallback((element) => React.isValidElement(element) && element.type === FilterNavigationItem, [FilterNavigationItem]);
    const handleSelect = useCallback((nextSelected) => {
        onSelect(nextSelected);
    }, [listRef, onSelect]);
    const makeClickHandler = (nextSelected) => {
        return () => {
            if (nextSelected !== selected)
                handleSelect(nextSelected);
        };
    };
    const isRtl = (listRef === null || listRef === void 0 ? void 0 : listRef.current) && getComputedStyle(listRef === null || listRef === void 0 ? void 0 : listRef.current).direction === 'rtl';
    const keyDownHandler = (event) => {
        var _a, _b, _c;
        if (event.key === 'Tab')
            return;
        event.preventDefault();
        const navItems = Array.from((_b = (_a = listRef.current) === null || _a === void 0 ? void 0 : _a.querySelectorAll(`${NavBarItem} a`)) !== null && _b !== void 0 ? _b : []);
        const focusIndex = navItems.findIndex((element) => element === event.target);
        if ([' ', 'Enter'].includes(event.key)) {
            handleSelect((_c = navItems[focusIndex]) === null || _c === void 0 ? void 0 : _c.parentElement.getAttribute('id'));
            return;
        }
        const prevKey = isRtl ? 'ArrowRight' : 'ArrowLeft';
        const nextKey = isRtl ? 'ArrowLeft' : 'ArrowRight';
        let nextFocusIndex = focusIndex;
        switch (event.key) {
            case 'Home':
                nextFocusIndex = 0;
                break;
            case 'End':
                nextFocusIndex = navItems.length - 1;
                break;
            case prevKey:
                nextFocusIndex = focusIndex === 0 ? navItems.length - 1 : focusIndex - 1;
                break;
            case nextKey:
                nextFocusIndex = (focusIndex + 1) % navItems.length;
                break;
            default:
                nextFocusIndex = focusIndex;
        }
        if (nextFocusIndex !== focusIndex) {
            const nextSelected = navItems[nextFocusIndex];
            if (nextSelected)
                nextSelected.focus();
        }
    };
    const scrollContainerRef = useRef(null);
    const scrollContentsRef = useRef(null);
    const { canScroll, canScrollBackward, canScrollForward, scrollIntoView, scrollBy } = useScroll(scrollContainerRef, scrollContentsRef);
    const showScrollButtons = canScroll;
    const scrollByViewWidth = useCallback((multiplier) => {
        return () => scrollContainerRef.current &&
            scrollBy({
                left: scrollContainerRef.current.offsetWidth * multiplier,
                behavior: 'smooth',
            });
    }, [scrollContainerRef, scrollBy]);
    const disableScrollPrevious = !canScrollBackward;
    const disableScrollNext = !canScrollForward;
    const scrollPrevious = scrollByViewWidth(isRtl ? scrollMultiplier : -scrollMultiplier);
    const scrollNext = scrollByViewWidth(isRtl ? -scrollMultiplier : scrollMultiplier);
    useEffect(() => {
        scrollIntoView(selectedItemRef);
    }, [scrollIntoView, selected]);
    return (React.createElement(NavBarContainer, { id: id, ref: listRef },
        showScrollButtons && (React.createElement(NavBarScrollControl, { position: "start", displayed: !!disableScrollPrevious, onClick: scrollPrevious },
            React.createElement(ScrollIcon, null))),
        React.createElement(NavBarScrollContainer, { as: "nav", ref: scrollContainerRef, "aria-labelledby": "filter-headline-mobile" },
            React.createElement(NavBarList, { ref: scrollContentsRef }, React.Children.map(children, (child) => {
                if (isItem(child)) {
                    const isSelected = child.props.id === selected;
                    const modified = React.cloneElement(child, Object.assign({ selected: isSelected, onClick: makeClickHandler(child.props.id), onKeyDown: keyDownHandler }, (isSelected && { ref: selectedItemRef })));
                    return modified;
                }
                return child;
            }))),
        showScrollButtons && (React.createElement(NavBarScrollControl, Object.assign({ position: "end" }, (disableScrollNext && {
            disabled: true,
        }), { onClick: scrollNext }),
            React.createElement(ScrollIcon, null)))));
};
const ScrollIcon = () => (React.createElement(NavBarScrollIcon, { width: "24", height: "24", viewBox: "0 0 24 24", xmlns: "http://www.w3.org/2000/svg" },
    React.createElement("path", { d: "M10 7L15 12 10 17", stroke: "currentColor", strokeWidth: "1", fill: "none", fillRule: "evenodd" })));
export default FilterNavigationBar;
