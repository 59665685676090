import React, { useMemo, useRef, useState } from 'react';
import { Button, ThemeProvider, Text, audiDarkTheme, audiLightTheme } from '@audi/audi-ui-react-v2';
import ReactDOM from 'react-dom';
import { useI18n } from '@oneaudi/i18n-context';
import { EquipmentComponent, EquipmentFilterOverlayNavigationHeaderMobile, EquipmentOverlayWrapper, } from '../styles/EquipmentFilter.styles';
import { useServicesContext } from '../../../context';
import { getFiltersInCategoryArray } from '../utils/utils';
import { FilterOverlayFooter } from './FilterOverlayFooter';
import { FilterOverlayAccordion } from './FilterOverlayAccordion';
import { FilterOverlayAccordionSection } from './FilterOverlayAccordionSection';
import { ANCHOR_MOBILE, EQSECTION } from './Filter';
import { trackClicks } from '../tracking';
import { FilterContext } from '../FilterContext';
import { useScsUrlParts } from '../../../hooks';
export const EquipmentAccordion = ({ configFilterCategory, categories, className, mobileOrDesktop, overlayRef, equipmentFilterHeadingForTracking, renderFilters, }) => {
    var _a;
    const scsUrlParts = useScsUrlParts();
    const isUsedCarMkt = (_a = scsUrlParts === null || scsUrlParts === void 0 ? void 0 : scsUrlParts.marketPath) === null || _a === void 0 ? void 0 : _a.includes('uc');
    const market = isUsedCarMkt ? 'uc' : 'nc';
    const subCategoryHeading1 = useI18n({
        id: `nemo.sc.filter.equipmentfilter.subCategoryHeading1.${market}`,
        defaultMessage: 'Lines & Packages',
    });
    const subCategoryHeading2 = useI18n({
        id: `nemo.sc.filter.equipmentfilter.subCategoryHeading2.${market}`,
        defaultMessage: 'Exterieur',
    });
    const subCategoryHeading3 = useI18n({
        id: `nemo.sc.filter.equipmentfilter.subCategoryHeading3.${market}`,
        defaultMessage: 'Inteurieur',
    });
    const subCategoryHeading4 = useI18n({
        id: `nemo.sc.filter.equipmentfilter.subCategoryHeading4.${market}`,
        defaultMessage: 'Technology & Security',
    });
    const subCategoryHeadings = [
        subCategoryHeading1,
        subCategoryHeading2,
        subCategoryHeading3,
        subCategoryHeading4,
    ];
    const accordionSectionHeadingLevel = mobileOrDesktop === 'mobile' ? 'h4' : 'h5';
    const filterGroupHeadingLevel = mobileOrDesktop === 'mobile' ? 'h5' : 'h6';
    return (React.createElement(FilterOverlayAccordion, { className: `equipment-accordion ${className}`, isUsedInEquipmentFilter: true, overlayRef: overlayRef, mobileOrDesktop: mobileOrDesktop }, categories === null || categories === void 0 ? void 0 : categories.map((category, index) => {
        return (React.createElement(FilterOverlayAccordionSection, { key: index, id: `${EQSECTION}${index + 1}${ANCHOR_MOBILE}`, label: subCategoryHeadings[index], accordionSectionHeadingLevel: accordionSectionHeadingLevel, equipmentFilterHeadingForTracking: equipmentFilterHeadingForTracking, configFilterCategory: configFilterCategory }, renderFilters(category, mobileOrDesktop, filterGroupHeadingLevel)));
    })));
};
export const EquipmentFilter = ({ configFilterCategory, equipmentFilterCategories, equipmentFilterCategoryLabel, renderFilters, closeOverlay, clearAllFilters, }) => {
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    let { lightMode } = audiContentService.getContent().fields;
    const context = React.useContext(FilterContext);
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const showAllEquipmentFiltersButtonAndHeading = useI18n({
        id: `nemo.sc.filter.equipmentfilter.showAllEquipmentFiltersButtonAndHeading`,
        defaultMessage: 'See all equipment',
    });
    const equipmentFilterOverlayDescription = useI18n({
        id: `nemo.sc.filter.equipmentfilter.overlayDescription`,
        defaultMessage: 'All equipment by category',
    });
    const EquipmentFilterCloseButtonAriaLabel = useI18n({
        id: `nemo.sc.filter.equipmentfilter.closeButtonAriaLabel`,
        defaultMessage: 'Close the equipment filter overlay and return to the general filter overview',
    });
    const layerManager = useServicesContext().getAdditionalService('layer-manager');
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const [equipmentLayerNode, setEquipmentLayerNode] = useState();
    const [equipmentLayer, setEquipmentLayer] = useState();
    const equipmentLayerRef = React.useCallback((node) => {
        setEquipmentLayerNode(node);
    }, [setEquipmentLayerNode]);
    const equipmentOverlayRef = useRef(null);
    const openOverlay = () => {
        const layer = layerManager.openFullscreenLayer(() => React.createElement("div", { ref: equipmentLayerRef }), {}, {
            onClose: () => handleCloseEquipmentOverlay(),
        });
        setEquipmentLayer(layer);
    };
    const handleCloseEquipmentOverlay = () => {
        equipmentLayer === null || equipmentLayer === void 0 ? void 0 : equipmentLayer.close();
    };
    const handleCloseBothOverlays = () => {
        handleCloseEquipmentOverlay();
        closeOverlay();
    };
    const handleClick = () => {
        const trackingEventName = 'vtp filter - click on category - layer open';
        trackClicks(trackingService, trackingEventName, showAllEquipmentFiltersButtonAndHeading, 'button', equipmentFilterCategoryLabel, false);
        openOverlay();
    };
    const someCategoriesAreEmpty = useMemo(() => {
        return equipmentFilterCategories.some((eqcategory) => eqcategory.filterGroups.length === 0);
    }, [equipmentFilterCategories]);
    const equipmentCategories = equipmentFilterCategories === null || equipmentFilterCategories === void 0 ? void 0 : equipmentFilterCategories.map((category) => {
        return Object.assign(Object.assign({}, category), { selectedFilters: 0, filterGroups: getFiltersInCategoryArray(category) });
    });
    const popularItemsCategory = equipmentCategories.shift();
    if (someCategoriesAreEmpty) {
        console.warn('There are filterGroups missing in at least one of the %cEquipment-Filter', 'color: yellow; font-style: bold; background-color: blue; padding: 2px', ' Sub-Categories. Please configure the filterGroups in the equipmentFilter configuration section.');
        return null;
    }
    return (React.createElement(EquipmentComponent, { className: "equipment-component", "data-testid": "equipment-filter-component" },
        renderFilters(popularItemsCategory, 'desktop'),
        React.createElement(Text, { as: "h4", variant: "order4", weight: "bold", spaceStackStart: "l", spaceStackEnd: "l", className: "show-equipment-sub-heading-desktop" }, showAllEquipmentFiltersButtonAndHeading),
        React.createElement(Button, { spaceStackEnd: "xl", spaceStackStart: "xl", variant: "text", onClick: handleClick, "data-testid": "close-equipment-filter-button", className: "show-equipment-filter-overlay-button-mobile", key: "showallequipmentbutton" }, showAllEquipmentFiltersButtonAndHeading),
        equipmentLayerNode &&
            ReactDOM.createPortal(React.createElement(EquipmentOverlayWrapper, { ref: equipmentOverlayRef, lightMode: lightMode },
                React.createElement(ThemeProvider, { theme: audiDarkTheme },
                    React.createElement(EquipmentFilterOverlayNavigationHeaderMobile, { className: "equipment-filter-layer-header" },
                        React.createElement(Button, { variant: "icon-tertiary", icon: "back", onClick: handleCloseEquipmentOverlay, "aria-label": EquipmentFilterCloseButtonAriaLabel, "data-tracking-exclude": true }),
                        React.createElement(Text, { as: "h2", variant: "order4", weight: "bold", spaceStackStart: "l", spaceStackEnd: "l" }, showAllEquipmentFiltersButtonAndHeading))),
                React.createElement(ThemeProvider, { theme: lightMode ? audiLightTheme : audiDarkTheme },
                    React.createElement("div", { className: `equipment-filter-layer-accordion-heading ${lightMode && 'lightMode'}` },
                        React.createElement(Text, { spaceStackStart: "xl", spaceStackEnd: "l", spaceInlineStart: "l", spaceInlineEnd: "l", variant: "order4", as: "h3" }, equipmentFilterOverlayDescription)),
                    React.createElement(EquipmentAccordion, { configFilterCategory: configFilterCategory, categories: equipmentCategories, renderFilters: renderFilters, mobileOrDesktop: "mobile", className: "equipment-filter--mobile", overlayRef: equipmentOverlayRef, equipmentFilterHeadingForTracking: showAllEquipmentFiltersButtonAndHeading }),
                    React.createElement(FilterOverlayFooter, { closeOverlay: handleCloseBothOverlays, clearAllFilters: clearAllFilters }))), equipmentLayerNode),
        React.createElement(EquipmentAccordion, { configFilterCategory: configFilterCategory, categories: equipmentCategories, renderFilters: renderFilters, mobileOrDesktop: "desktop", className: "equipment-filter--desktop", equipmentFilterHeadingForTracking: showAllEquipmentFiltersButtonAndHeading })));
};
