import React, { useState, useEffect } from 'react';
import { carTypeIcons } from './CarTypeIcons';
import parse from 'html-react-parser';
import { IconLabelWrapper, LabelCountWrapper, StyledCheckbox, StyledCheckboxWrapper, } from '../styles/CheckboxFilter.styles';
import { useApplyFilters } from '../FilterContextHooks';
import { formatCount } from '../../../utils';
import { useServicesContext } from '../../../context';
import InfoButton from './InfoButton';
import { FilterContext } from '../FilterContext';
export const CheckboxSimple = ({ groupHeadline, filterCategory, isCarTypeIconCheckbox, itemHeadline, itemText, visibleIn, }) => {
    const applyFilters = useApplyFilters();
    const context = React.useContext(FilterContext);
    const { getAdditionalService } = useServicesContext();
    const localeService = getAdditionalService('gfa:locale-service');
    const locale = `${localeService.language}-${localeService.countryCode}`;
    const [active, setActive] = useState(false);
    const [count, setCount] = useState();
    const [isChecked, setIsChecked] = useState(false);
    const isDisabled = count <= 0;
    const handleChange = () => {
        const filterDTO = {
            id: itemHeadline,
            active: !isChecked,
        };
        const currentFilter = { id: itemHeadline, values: [] };
        const trackingEventName = !isChecked ? 'vtp filter - add filter' : 'vtp filter - remove filter';
        const trackingData = {
            trackingEventName,
            trackingLabel: itemText,
            trackingValue: filterCategory,
            changedFilter: [currentFilter],
        };
        setIsChecked(!isChecked);
        applyFilters(filterDTO, trackingData);
    };
    useEffect(() => {
        const checkboxFiltersData = context.filterData.groups;
        const checkboxFilterData = checkboxFiltersData[groupHeadline].members[itemHeadline];
        setActive(checkboxFilterData.active);
        setCount(checkboxFilterData.count);
    }, [context]);
    useEffect(() => {
        setIsChecked(active);
    }, [active]);
    const renderCarTypeIcon = () => {
        const carTypeIconString = carTypeIcons[itemHeadline] || carTypeIcons.fallback;
        if (carTypeIconString) {
            return parse(carTypeIconString);
        }
        return '';
    };
    const renderCarTypeIconCheckbox = () => {
        return (React.createElement(IconLabelWrapper, null,
            React.createElement("div", null, renderCarTypeIcon()),
            React.createElement(LabelCountWrapper, null,
                React.createElement("span", null,
                    itemText,
                    "\u00A0"),
                React.createElement("span", null,
                    "(",
                    formatCount(count, locale).toString(),
                    ")"))));
    };
    const renderCheckbox = () => {
        return `${itemText} (${formatCount(count, locale).toString()})`;
    };
    return (React.createElement(StyledCheckboxWrapper, null,
        React.createElement(StyledCheckbox, { hideLabelOptional: true, inputId: `checkbox${itemHeadline}_${visibleIn}`, checked: isChecked, disabled: isDisabled, onChange: handleChange, isCarTypeIconCheckbox: isCarTypeIconCheckbox }, isCarTypeIconCheckbox ? renderCarTypeIconCheckbox() : renderCheckbox()),
        React.createElement(InfoButton, { filterGroupIdOrFilterId: itemHeadline, filterOrGroupText: itemText, filterCategory: filterCategory })));
};
export default CheckboxSimple;
