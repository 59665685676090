var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback } from 'react';
import { FilterContext } from './FilterContext';
import { ACTIONS, ExpiringStorage, SELECTORS } from '@oneaudi/stck-store';
import { createFiltersDataString, createPresetsDataString, updateFiltersAndResults, } from './filterService';
import { useDispatch, useSelector } from 'react-redux';
import { useServicesContext } from '../../context';
import { useSvd, useScsUrlParts } from '../../hooks';
import { findSelectedFilterCategory } from './utils/filterHelper';
import { trackOneFilterChange } from './tracking';
export function useFilterData() {
    const context = React.useContext(FilterContext);
    return context.filterData;
}
export function updateFilters(filterData) {
    const context = React.useContext(FilterContext);
    context.setFilterData(filterData);
}
export function useRangeScope() {
    const context = React.useContext(FilterContext);
    return context.rangeScope;
}
export function useWholeMarketDealerData() {
    const context = React.useContext(FilterContext);
    return context.wholeMarketDealerData;
}
export function useImpressionTrackingCategories() {
    const context = React.useContext(FilterContext);
    return context.impressionTrackingCategoriesFired;
}
export function updateImpressionTrackingCategories() {
    const context = React.useContext(FilterContext);
    return useCallback((category) => {
        context.setImpressionTrackingCategoriesFired((prevArray) => [...prevArray, category]);
    }, []);
}
export function useSortParam() {
    const context = React.useContext(FilterContext);
    return context.sortParam;
}
export const useClearAllFilters = () => {
    const context = React.useContext(FilterContext);
    const dispatch = useDispatch();
    const svd = useSvd();
    const scsUrlParts = useScsUrlParts();
    const presets = useSelector((state) => SELECTORS.FILTERS.getPersistedPresetsMap(state));
    const configurationService = useServicesContext().getAdditionalService('vtp-configuration-service');
    const storeService = useServicesContext().getAdditionalService('audi-stockcars-store-service');
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    return useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        const localStorageLocationSearchInputKey = `VTP_${scsUrlParts.marketPath}_locationSearch--input`;
        const localStorage = new ExpiringStorage(localStorageLocationSearchInputKey, 30);
        localStorage.clearStorage();
        context.setRadius(undefined);
        context.setSearchedCoords(undefined);
        context.setPrevSearchedCoords(undefined);
        context.setWholeMarketDealersFetch(true);
        const defaultSortParam = (_b = (_a = configurationService === null || configurationService === void 0 ? void 0 : configurationService.getConfiguration()) === null || _a === void 0 ? void 0 : _a.sortParams) === null || _b === void 0 ? void 0 : _b.defaultOption;
        if (defaultSortParam)
            dispatch(ACTIONS.SORTING.setSorting({ sorting: { results: defaultSortParam } }));
        dispatch(ACTIONS.RESULT_VEHICLEIDS.clearResultVehicleIds());
        dispatch(ACTIONS.COMPARED_VEHICLES.resetCompareVehicleIds());
        const allActivePresetsDataStrings = createPresetsDataString(presets);
        const filterResponse = yield updateFiltersAndResults(svd.svd, [], allActivePresetsDataStrings, configurationService, storeService.store, dispatch, getConfiguration(), getEnvironmentConfig());
        context.setFilterData(filterResponse);
        dispatch(ACTIONS.UI.setLoader({ state: false, feature: 'filter' }));
    }), []);
};
export const useApplyFilters = () => {
    const context = React.useContext(FilterContext);
    const dispatch = useDispatch();
    const svd = useSvd();
    const configurationService = useServicesContext().getAdditionalService('vtp-configuration-service');
    const storeService = useServicesContext().getAdditionalService('audi-stockcars-store-service');
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    const { isUsedCarMkt } = useScsUrlParts();
    return useCallback((filterDTO, trackingData) => __awaiter(void 0, void 0, void 0, function* () {
        const storedFilters = SELECTORS.FILTERS.getPersistedFiltersMaps(context.store.getState());
        const storedPresets = SELECTORS.FILTERS.getPersistedPresetsMap(context.store.getState());
        const filters = new Map(storedFilters);
        const presets = new Map(storedPresets);
        const filterDTOs = Array.isArray(filterDTO) ? filterDTO : [filterDTO];
        filterDTOs.forEach((singleFilterDto) => {
            if (!singleFilterDto.active) {
                filters.delete(singleFilterDto.id);
            }
            else {
                filters.set(singleFilterDto.id, singleFilterDto);
            }
        });
        const allActiveFiltersDataStrings = createFiltersDataString(filters);
        const allActivePresetsDataStrings = createPresetsDataString(presets);
        const filterResponse = yield updateFiltersAndResults(svd.svd, allActiveFiltersDataStrings, allActivePresetsDataStrings, configurationService, storeService.store, dispatch, getConfiguration(), getEnvironmentConfig());
        context.setFilterData(filterResponse);
        if (trackingData) {
            const { trackingEventName, trackingLabel, trackingValue, changedFilter } = trackingData;
            const trackingRelatedFilters = [];
            const { filterOptions } = filterResponse;
            Object.keys(filterOptions).forEach((key) => {
                const value = filterOptions[key];
                if (value.allSelected && value.group === 'carlinegroups') {
                    const relatedFilter = { id: key, values: [] };
                    trackingRelatedFilters.push(relatedFilter);
                }
                else if (value.group === 'bodytypes') {
                }
                else if (value.group === 'dealer') {
                }
                else {
                    filterOptions[key].selectedFilter.forEach((item) => {
                        const values = key === 'ranges' ? item.remove : item.display;
                        const relatedFilter = {
                            id: item.id,
                            values: values.map((v) => (v === 'null' ? '' : v)),
                        };
                        return trackingRelatedFilters.push(relatedFilter);
                    });
                }
            });
            trackOneFilterChange(trackingService, trackingEventName, trackingLabel, trackingValue, changedFilter, trackingRelatedFilters, filterResponse.totalCount, isUsedCarMkt);
        }
        dispatch(ACTIONS.UI.setLoader({ state: false, feature: 'filter' }));
        return filterResponse;
    }), []);
};
export const useClearAllDealerFilters = () => {
    const context = React.useContext(FilterContext);
    let { filterData } = context;
    const dispatch = useDispatch();
    const svd = useSvd();
    const configurationService = useServicesContext().getAdditionalService('vtp-configuration-service');
    const storeService = useServicesContext().getAdditionalService('audi-stockcars-store-service');
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    return useCallback((geoFilterDTO) => __awaiter(void 0, void 0, void 0, function* () {
        const storedFilters = SELECTORS.FILTERS.getPersistedFiltersMaps(context.store.getState());
        const storedPresets = SELECTORS.FILTERS.getPersistedPresetsMap(context.store.getState());
        const filters = new Map(storedFilters);
        const presets = new Map(storedPresets);
        if (geoFilterDTO === null || geoFilterDTO === void 0 ? void 0 : geoFilterDTO.active) {
            filters.set(geoFilterDTO.id, geoFilterDTO);
        }
        else {
            filters.delete('geo');
        }
        [...filters.keys()].map((filter) => {
            if (filter.startsWith('dealer')) {
                return filters.delete(filter);
            }
            return filter;
        });
        const allActiveFiltersDataStrings = createFiltersDataString(filters);
        const allActivePresetsDataStrings = createPresetsDataString(presets);
        const filterResponse = yield updateFiltersAndResults(svd.svd, allActiveFiltersDataStrings, allActivePresetsDataStrings, configurationService, storeService.store, dispatch, getConfiguration(), getEnvironmentConfig());
        context.setFilterData(filterResponse);
        dispatch(ACTIONS.UI.setLoader({ state: false, feature: 'filter' }));
        filterData = filterResponse;
        return filterData;
    }), []);
};
export const useCountSelectedFilters = () => {
    const context = React.useContext(FilterContext);
    const { filterData } = context;
    const increment = useCallback((configuredCategory) => {
        let count = 0;
        if (filterData) {
            const appliedFilters = filterData.filterOptions;
            if (configuredCategory.isEquipmentFilter) {
                const selectedEquipmentFilters = [];
                Object.values(appliedFilters).forEach((value) => {
                    const groupIsPartOfEquipment = configuredCategory.filterGroups.find((item) => item.filter === value.group);
                    if (groupIsPartOfEquipment) {
                        value.selectedFilter.forEach((item) => {
                            return (!selectedEquipmentFilters.includes(item.id) &&
                                selectedEquipmentFilters.push(item.id));
                        });
                    }
                });
                count += selectedEquipmentFilters.length;
            }
            else {
                for (const [key, value] of Object.entries(appliedFilters)) {
                    let countFiltersInGroup;
                    switch (true) {
                        case key === 'ranges':
                            for (let i = 0; i < value.selectedFilter.length; i++) {
                                if (findSelectedFilterCategory(configuredCategory, value.selectedFilter[i].id))
                                    count += 1;
                            }
                            break;
                        case value.group === 'carlinegroups':
                            countFiltersInGroup = value.allSelected
                                ? value.selectedFilter[0].remove.length
                                : value.selectedFilter.length;
                            if (findSelectedFilterCategory(configuredCategory, 'carline'))
                                count += countFiltersInGroup;
                            break;
                        case value.group === 'dealer':
                            if (findSelectedFilterCategory(configuredCategory, 'location')) {
                                for (let i = 0; i < value.selectedFilter.length; i++) {
                                    if (value.selectedFilter[i].id !== 'geo')
                                        count += 1;
                                }
                            }
                            break;
                        default:
                            countFiltersInGroup = value.selectedFilter.length;
                            if (findSelectedFilterCategory(configuredCategory, key))
                                count += countFiltersInGroup;
                    }
                }
            }
        }
        const totalCount = count > 0 ? `(${count})` : '';
        return { totalCount };
    }, [filterData]);
    return { increment };
};
