import React, { useEffect, useState } from 'react';
import { DealerAccordionItemHeaderWrapper } from '../styles/FilterOverlay.styles';
import { Button, Checkbox } from '@audi/audi-ui-react-v2';
import { useServicesContext } from '../../../context';
import { FilterContext } from '../FilterContext';
export const DealerAccordionItemHeader = ({ id, panelId, count, label, dealerStatus, active, open, ariaLabelForAccordionButton, isCheckedControlledByClickOnMarker, isDisplayingFocusedDealerBellowMap, toggleRef, setSelectedDealer, handleToggle, }) => {
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    let { lightMode } = audiContentService.getContent().fields;
    const context = React.useContext(FilterContext);
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const arrowIcon = open ? 'arrow-up' : 'arrow-down';
    const arrowIconReverted = open ? 'arrow-down' : 'arrow-up';
    const [isChecked, setIsChecked] = useState(false);
    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
        if (setSelectedDealer) {
            setSelectedDealer({ dealerId: id, source: 'checkbox' });
        }
    };
    useEffect(() => {
        if (isCheckedControlledByClickOnMarker !== undefined) {
            setIsChecked(isCheckedControlledByClickOnMarker);
        }
    }, [isCheckedControlledByClickOnMarker]);
    useEffect(() => {
        if (active !== undefined) {
            setIsChecked(active);
        }
    }, [active]);
    return (React.createElement(DealerAccordionItemHeaderWrapper, { status: dealerStatus, open: open, lightMode: lightMode, isDisplayingFocusedDealerBellowMap: isDisplayingFocusedDealerBellowMap },
        React.createElement(Checkbox, { checked: isChecked, inputId: `checkbox_${id}`, disabled: count === 0, hideLabelOptional: true, spaceInlineStart: "s", spaceInlineEnd: "m", spaceStackStart: "m", spaceStackEnd: "m", onChange: () => handleCheckboxChange() }, label),
        React.createElement(Button, { icon: isDisplayingFocusedDealerBellowMap ? arrowIconReverted : arrowIcon, variant: "icon-tertiary", id: id, "aria-controls": panelId, "aria-expanded": open, "aria-label": ariaLabelForAccordionButton, onClick: handleToggle, ref: toggleRef })));
};
