import React, { useEffect } from 'react';
import { audiDarkTheme, audiLightTheme, Text, ThemeProvider } from '@audi/audi-ui-react-v2';
import { FilterOverlayBodyContainer, FilterOverlayContent, ContentSection, RenderedFiltersWrapper, HeadingWrapper, } from '../styles/FilterOverlay.styles';
import { useI18n } from '@oneaudi/i18n-context';
import { SECTION, OVERLAY_ANCHOR_DESKTOP, TARGET_DESKTOP } from './Filter';
import { useServicesContext } from '../../../context';
import { trackImpression } from '../tracking';
import { EquipmentFilter } from './EquipmentFilter';
import { useImpressionTrackingCategories, updateImpressionTrackingCategories, } from '../FilterContextHooks';
import { FilterContext } from '../FilterContext';
export const FilterOverlayBody = ({ configuredFilters, openedChipsContainer, overlayRef, sectionsRef, renderFilters, closeOverlay, clearAllFilters, setOverlayNavBarState, equipmentFilterCategories, }) => {
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    let { lightMode } = audiContentService.getContent().fields;
    const context = React.useContext(FilterContext);
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const contentSectionHeadlinePattern = useI18n({
        id: `nemo.sc.filter.contentSectionHeadlinePattern`,
        defaultMessage: 'Filter by {{category}}',
    });
    let timeout;
    const impressionTrackingCategoriesFired = useImpressionTrackingCategories();
    const updateTrackingCategories = updateImpressionTrackingCategories();
    const handleScroll = (e) => {
        const pageYOffset = e.srcElement.scrollTop;
        let newActiveSection;
        let newActiveCategory = '';
        if (sectionsRef.current.length > 0) {
            sectionsRef.current.forEach((section) => {
                const sectionOffsetTop = section === null || section === void 0 ? void 0 : section.offsetTop;
                const sectionHeight = section === null || section === void 0 ? void 0 : section.offsetHeight;
                const oneThirdInnerHeight = typeof window !== 'undefined' && window.innerHeight * 0.35;
                if (pageYOffset + oneThirdInnerHeight >= sectionOffsetTop &&
                    pageYOffset < sectionOffsetTop + sectionHeight) {
                    newActiveSection = section.id.replace(`${TARGET_DESKTOP}`, `${OVERLAY_ANCHOR_DESKTOP}`);
                    newActiveCategory = section.firstElementChild.getAttribute('data-trackid');
                }
            });
            if (newActiveSection) {
                setOverlayNavBarState(newActiveSection);
                if (timeout) {
                    clearTimeout(timeout);
                }
                if (!impressionTrackingCategoriesFired.includes(newActiveCategory)) {
                    timeout = setTimeout(() => {
                        fireImpressionCategoryTracking(newActiveCategory);
                    }, 1000);
                }
            }
        }
    };
    const fireImpressionCategoryTracking = (category) => {
        trackImpression(trackingService, category);
        updateTrackingCategories(category);
    };
    useEffect(() => {
        var _a, _b;
        (_a = overlayRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', handleScroll);
        (_b = overlayRef.current) === null || _b === void 0 ? void 0 : _b.addEventListener('scroll', handleScroll, { passive: true });
        return () => {
            var _a;
            (_a = overlayRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', handleScroll);
        };
    }, [overlayRef, impressionTrackingCategoriesFired]);
    return (React.createElement(ThemeProvider, { theme: lightMode ? audiLightTheme : audiDarkTheme },
        React.createElement(FilterOverlayBodyContainer, { openedChipsContainer: openedChipsContainer, lightMode: lightMode },
            React.createElement(FilterOverlayContent, { "data-testid": "OverlayDesktop" }, configuredFilters.map((configuredCategory, index) => {
                return (React.createElement(ContentSection, { key: index, id: `${SECTION}${index + 1}${TARGET_DESKTOP}`, ref: (element) => sectionsRef.current.splice(index, 1, element), "aria-describedby": `${SECTION}${index + 1}Heading` },
                    React.createElement(HeadingWrapper, { "data-trackid": configuredCategory.filterCategory },
                        React.createElement(Text, { as: "h3", variant: "order2", spaceStackEnd: "xl", id: `${SECTION}${index + 1}Heading` },
                            contentSectionHeadlinePattern.replace('{{category}}', configuredCategory.filterCategory),
                            React.createElement("span", { id: `${SECTION}${index + 1}FocusElement`, tabIndex: -1 }))),
                    !configuredCategory.isEquipmentFilter && (React.createElement(RenderedFiltersWrapper, null, renderFilters(configuredCategory, 'desktop'))),
                    configuredCategory.isEquipmentFilter && equipmentFilterCategories && (React.createElement(EquipmentFilter, { configFilterCategory: configuredCategory === null || configuredCategory === void 0 ? void 0 : configuredCategory.filterCategory, equipmentFilterCategories: equipmentFilterCategories, equipmentFilterCategoryLabel: configuredCategory.filterCategory, renderFilters: renderFilters, closeOverlay: closeOverlay, clearAllFilters: clearAllFilters }))));
            })))));
};
export default FilterOverlayBody;
