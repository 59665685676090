import styled from 'styled-components';
export const EquipmentOverlayWrapper = styled.div `
  background-color: var(
    ${(props) => props.lightMode ? props.theme.colors.base.brand.white : props.theme.colors.base.grey[90]}
  );
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  scroll-behavior: smooth;

  .equipment-filter-layer-header {
    position: fixed;
    top: 0;
    z-index: 4;
  }

  .equipment-filter-layer-accordion-heading {
    margin-top: 74px;

    &.lightMode {
      background-color: var(${(props) => props.theme.colors.base.brand.white});
    }
  }

  .equipment-filter--mobile {
    background-color: var(${(props) => props.theme.colors.base.grey[90]});
    margin-bottom: 88px;
  }

  .equipment-filter--mobile > div:nth-child(odd) {
    background-color: var(${(props) => props.theme.colors.base.grey[90]});
  }
`;
export const EquipmentFilterOverlayNavigationHeaderMobile = styled.div `
  display: grid;
  justify-items: center;
  align-items: center;
  grid-template-columns: 56px 1fr 56px;
  background-color: var(${(props) => props.theme.colors.base.brand.black});
  border-bottom: solid var(${(props) => props.theme.colors.ui.divider});
  border-width: 1px 0;
  width: 100%;
`;
export const EquipmentComponent = styled.div `
  /* Only the non-accordion filterGroup is laid out differently */
  > div > fieldset {
    @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
      grid-template-columns: repeat(2, 1fr);
    }
    @media (min-width: ${(props) => props.theme.breakpoints.l}px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .equipment-accordion > div > div {
    display: grid;
    column-gap: var(${(props) => props.theme.responsive.spacing.xl});

    @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (min-width: ${(props) => props.theme.breakpoints.xl}px) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .show-equipment-sub-heading-desktop {
    display: none;

    @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
      display: block;
    }
  }

  .equipment-filter--mobile,
  .show-equipment-filter-overlay-button-mobile {
    @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
      display: none;
    }
  }
  .equipment-filter--desktop {
    display: none;
    @media (min-width: ${(props) => props.theme.breakpoints.m}px) {
      display: grid;
    }
  }
`;
export const EquipmentFilterOverlayFooterContainer = styled.div `
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: var(${(props) => props.theme.colors.base.brand.black});
  border-top: solid var(${(props) => props.theme.colors.ui.divider});
  border-width: 1px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  left: 0;
`;
