import React, { useEffect, useState } from 'react';
import {
  useServicesContext,
  useSvd,
  Filter,
  FilterContextProvider,
  cleanupFilterURLHash,
  entryFilters,
  getRangeScope,
  getDealers,
  FilterResponse,
  DealerResponse,
  RangeScope,
  OneCMSContent,
  addEquipmentFilterToFilterGroups,
  formatEquipmentFilterData,
  formatFilterCategories,
  SeoFilterValue,
} from '@oneaudi/vtp-shared';
import { useDispatch } from 'react-redux';
import { AudiStockcarsStoreServiceV1 } from '@oneaudi/stockcars-store-service';
import { ConfigurationServiceV1 } from '@oneaudi/vtp-configuration-service';
import { ContentService } from '@oneaudi/content-service';

interface FeatureAppProps {
  seoFilters: SeoFilterValue[];
}

const FeatureApp: React.FC<FeatureAppProps> = ({ seoFilters }) => {
  const { getAdditionalService } = useServicesContext();
  const configurationService = getAdditionalService(
    'vtp-configuration-service'
  ) as ConfigurationServiceV1;
  const storeService = getAdditionalService(
    'audi-stockcars-store-service'
  ) as AudiStockcarsStoreServiceV1;
  const contentService = getAdditionalService('audi-content-service') as ContentService;

  const {
    filterCategories,
    equipmentFilter_introCategory,
    equipmentFilter_subCategories,
    filterHandlerOnly,
  } = contentService.getContent().fields as OneCMSContent;
  const configEquipmentFilterCategory =
    filterCategories?.find((cat) => cat?.fields?.filterGroup01 === 'equipment.equipment-block')
      ?.fields?.filterCategory || 'Equipment';
  const { filterHandlerOnly: filterHandlerOnlyConfig } = useServicesContext()
    .featureAppConfig as any;
  const dispatch = useDispatch();
  const { getConfiguration, getEnvironmentConfig } = useServicesContext();
  const { svd } = useSvd();

  const vtpConfiguration = getConfiguration();
  const envConfig = getEnvironmentConfig();

  const equipmentFilterProperlyFormatted =
    equipmentFilter_introCategory && equipmentFilter_subCategories
      ? formatEquipmentFilterData(
          configEquipmentFilterCategory,
          equipmentFilter_introCategory,
          equipmentFilter_subCategories
        )
      : [];

  const filterCategoriesFormatted = formatFilterCategories(filterCategories);

  const allConfiguredFilterCategories = addEquipmentFilterToFilterGroups(
    equipmentFilterProperlyFormatted,
    filterCategoriesFormatted
  );

  const [filterData, setFilterData] = useState<FilterResponse | undefined>(undefined);
  const [rangeScope, setRangeScope] = useState<RangeScope[]>();
  const [wholeMarketDealerData, setWholeMarketDealerData] = useState<DealerResponse | undefined>(
    undefined
  );

  useEffect(() => {
    let isLocationFilterConfigured = false;
    allConfiguredFilterCategories.forEach((configuredFilterCategory: any) => {
      const locationFilterFound = configuredFilterCategory.filterGroups.find(
        (filterGroup: any) => filterGroup.filterGroup === 'location.location'
      );
      if (locationFilterFound) isLocationFilterConfigured = true;
    });

    if (isLocationFilterConfigured) {
      const fetchWholeMarketDealers = async () => {
        const dealerResponse = await getDealers(vtpConfiguration!, envConfig!);
        setWholeMarketDealerData(dealerResponse);
      };
      // eslint-disable-next-line no-console
      fetchWholeMarketDealers().catch(console.error);
    }
  }, []);

  useEffect(() => {
    if (svd) {
      const fetchFilters = async () => {
        const filterResponse = await entryFilters(
          storeService.store,
          configurationService,
          dispatch,
          vtpConfiguration!,
          envConfig!,
          svd,
          seoFilters
        );
        setFilterData(filterResponse!);
        cleanupFilterURLHash();
      };
      if (!filterData || (typeof window !== 'undefined' && window.location.hash)) {
        fetchFilters();
      }
      if (typeof window !== 'undefined') {
        window.addEventListener('hashchange', () => {
          fetchFilters();
        });
      }
    }
  }, [svd, filterData]);

  useEffect(() => {
    const fetchRangeScope = async () => {
      const rangeScopeArray = await getRangeScope(
        envConfig!.scs.baseUrl,
        vtpConfiguration!.scs!.scsMarketPath!,
        envConfig!.scs.defaultApiVersion,
        svd
      );
      setRangeScope(rangeScopeArray);
    };
    if (svd) fetchRangeScope();
  }, [svd]);

  if (!filterData || filterHandlerOnly || filterHandlerOnlyConfig) {
    return null;
  }

  return (
    <div data-audi-core-tracking-exclude data-audi-core-tracking-include="false">
      <FilterContextProvider
        configurationService={configurationService}
        rangeScopes={rangeScope}
        initialFilterData={filterData}
        initialWholeMarketDealerData={wholeMarketDealerData}
      >
        <Filter
          allConfiguredFilterCategories={allConfiguredFilterCategories}
          equipmentFilterProperlyFormatted={equipmentFilterProperlyFormatted}
        />
      </FilterContextProvider>
    </div>
  );
};

export default FeatureApp;
