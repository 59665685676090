import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SELECTORS } from '@oneaudi/stck-store';
import { formatCount } from '../../../utils';
import { useServicesContext } from '../../../context';
import { useScsUrlParts } from '../../../hooks';
import { useI18n } from '@oneaudi/i18n-context';
import { FilterOverlayFooterContainer, StyledResultsButtonWrapper, StyledDeleteFiltersButtonWrapper, StyledResultsButton, } from '../styles/FilterOverlay.styles';
import { Button, ThemeProvider, audiLightTheme, audiDarkTheme } from '@audi/audi-ui-react-v2';
import { trackClicks, trackRemoveAllFilters } from '../tracking';
import { FilterContext } from '../FilterContext';
export const FilterOverlayFooter = ({ closeOverlay, clearAllFilters, }) => {
    const trackingService = useServicesContext().getAdditionalService('audi-tracking-service');
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    let { lightMode } = audiContentService.getContent().fields;
    const context = React.useContext(FilterContext);
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const { isUsedCarMkt } = useScsUrlParts();
    const resultsButtonLabel = useI18n({
        id: `nemo.sc.filter.resultsButton`,
        defaultMessage: '',
    });
    const deleteFiltersLabel = useI18n({
        id: `nemo.ui.sc.filter-options.reset.label`,
        defaultMessage: '',
    });
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const locale = `${localeService.language}-${localeService.countryCode}`;
    const totalResults = useSelector((state) => SELECTORS.VEHICLES.getTotalCountState(state));
    const formattedResultsCount = formatCount(totalResults, locale);
    const allSelectedFilters = useSelector((state) => SELECTORS.FILTERS.getSelectedFiltersMap(state));
    const [isLoadingNewFilterData, setIsLoadingNewFilterData] = useState(false);
    useSelector((state) => {
        const getLoadingStateFromRedux = SELECTORS.UI.getLoadingState(state);
        if (getLoadingStateFromRedux !== isLoadingNewFilterData) {
            setIsLoadingNewFilterData(getLoadingStateFromRedux);
        }
    });
    const handleResultsButtonClick = () => {
        closeOverlay();
        const trackingEventName = 'vtp filter - click to show results';
        trackClicks(trackingService, trackingEventName, `${formattedResultsCount} ${resultsButtonLabel}`, 'button', '', false);
    };
    const handleDeleteButtonClick = () => {
        const trackingRelatedFilters = [];
        [...allSelectedFilters.values()].forEach((filter) => {
            const relatedFilter = { id: filter.id, values: filter.values || [] };
            trackingRelatedFilters.push(relatedFilter);
        });
        trackRemoveAllFilters(trackingService, 'text link', deleteFiltersLabel, trackingRelatedFilters, totalResults, isUsedCarMkt);
        clearAllFilters();
    };
    const resultButton = useMemo(() => {
        return (React.createElement(ThemeProvider, { theme: lightMode ? audiLightTheme : audiDarkTheme },
            React.createElement(StyledResultsButton, { "data-testid": "ResultButton", variant: "secondary", size: "medium", spaceStackStart: "m", spaceStackEnd: "m", onClick: handleResultsButtonClick, loading: isLoadingNewFilterData, "data-tracking-exclude": true, lightMode: lightMode }, `${formattedResultsCount} ${resultsButtonLabel}`)));
    }, [isLoadingNewFilterData]);
    return (React.createElement(FilterOverlayFooterContainer, null,
        React.createElement(StyledResultsButtonWrapper, null, resultButton),
        React.createElement(StyledDeleteFiltersButtonWrapper, null,
            React.createElement(Button, { variant: "text", icon: "erase", size: "small", onClick: handleDeleteButtonClick }, deleteFiltersLabel))));
};
export default FilterOverlayFooter;
