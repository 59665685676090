import React from 'react';
import { audiDarkTheme, audiLightTheme } from '@audi/audi-ui-react-v2';
import { useI18n } from '@oneaudi/i18n-context';
import { OuterChipsContainer, ChipsContent, StyledDivider, StyledTextChosenFilters, } from '../styles/FilterOverlay.styles';
import { ChipsList } from './ChipsList';
import { ThemeProvider } from 'styled-components';
import { useServicesContext } from '../../../context';
import { FilterContext } from '../FilterContext';
export const FilterChips = ({ configuredFilters }) => {
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    let { lightMode } = audiContentService.getContent().fields;
    const context = React.useContext(FilterContext);
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const chosenFiltersLabel = useI18n({
        id: `nemo.sc.filter.chosenFilters`,
        defaultMessage: 'Selected Filters',
    });
    return (React.createElement(ThemeProvider, { theme: lightMode ? audiLightTheme : audiDarkTheme },
        React.createElement(OuterChipsContainer, { lightMode: lightMode },
            React.createElement(ChipsContent, null,
                React.createElement(StyledDivider, { lightMode: lightMode }),
                React.createElement(StyledTextChosenFilters, { id: "chosenfilterchipslabel", lightMode: lightMode }, chosenFiltersLabel),
                React.createElement(ChipsList, { configuredFilters: configuredFilters })))));
};
export default FilterChips;
