export const rangeIncrements = [
    {
        rangeFilter: 'acceleration',
        increment: 1,
    },
    {
        rangeFilter: 'co2EmissionCombined',
        increment: 10,
    },
    {
        rangeFilter: 'consumption',
        increment: 1,
    },
    {
        rangeFilter: 'displacement',
        increment: 500,
    },
    {
        rangeFilter: 'doors',
        increment: 2,
    },
    {
        rangeFilter: 'luggageCompartmentCapacity',
        increment: 10,
    },
    {
        rangeFilter: 'modelYear',
        increment: 1,
    },
    {
        rangeFilter: 'prices.rate',
        increment: 50,
    },
    {
        rangeFilter: 'powerKw',
        increment: 10,
    },
    {
        rangeFilter: 'productionYear',
        increment: 1,
    },
    {
        rangeFilter: 'salesModelYear',
        increment: 1,
    },
    {
        rangeFilter: 'usedCarInitialRegistrationYear',
        increment: 1,
    },
    {
        rangeFilter: 'usedNumPreviousOwners',
        increment: 1,
    },
    {
        rangeFilter: 'usedCarMileage',
        increment: 10000,
    },
    {
        rangeFilter: 'default',
        increment: 10,
    },
];
