import React, { useMemo, useEffect } from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { LocationFilterHeaderContainer, LocationInputContainer, SearchWrapper, StyledSelect, ClearAllLocationsButton, LocateMeButton, ConsentRequestWrapperMobile, } from '../styles/LocationFilter.styles';
import { Text } from '@audi/audi-ui-react-v2';
import { useServicesContext } from '../../../context';
import { useDesktopOrMobileView } from '../../../hooks';
import { useFilterData, useClearAllDealerFilters } from '../FilterContextHooks';
import CookieConsentRequest from './CookieConsentRequest';
import { useGoogleConsent } from '@oneaudi/two-click';
import { FilterContext } from '../FilterContext';
import { setLocationFilterToDefaultState } from '../utils/locationFilterHelpers';
import { Search } from '@audi/audi-ui-react';
export const LocationFilterHeader = ({ inputRef, mapConsentButtonText, overlayRef, openConsentOverlay, setIsTwoClickOverlayOpened, handleLocateMeClick, }) => {
    const consent = useGoogleConsent();
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    const context = React.useContext(FilterContext);
    const { radius, setRadius, searchedCoords } = context;
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    let { mileageUnit, lightMode } = audiContentService.getContent().fields;
    const { locationFilterConfig_radiusOptions } = audiContentService.getContent()
        .fields;
    const { isPlpFlag } = context;
    if (isPlpFlag) {
        lightMode = true;
    }
    const radiusOptions = locationFilterConfig_radiusOptions || [10, 20, 50, 100, 200];
    const clearAllDealerFilters = useClearAllDealerFilters();
    const filterData = useFilterData();
    const isDesktop = useDesktopOrMobileView('1024px') === 'desktop';
    const isMobile = useDesktopOrMobileView('767px') === 'mobile';
    const isSomeDealerActive = useMemo(() => {
        var _a, _b;
        const dealersFilterData = (_b = (_a = filterData === null || filterData === void 0 ? void 0 : filterData.groups) === null || _a === void 0 ? void 0 : _a.dealers) === null || _b === void 0 ? void 0 : _b.members;
        return Object.values(dealersFilterData).some((obj) => {
            return obj.active === true;
        });
    }, [filterData === null || filterData === void 0 ? void 0 : filterData.groups.dealers.members]);
    useEffect(() => {
        if (inputRef) {
            const onScroll = () => {
                if ((inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) && (inputRef === null || inputRef === void 0 ? void 0 : inputRef.current) === document.activeElement) {
                    inputRef === null || inputRef === void 0 ? void 0 : inputRef.current.blur();
                }
            };
            if (typeof window !== 'undefined') {
                overlayRef.current.removeEventListener('scroll', onScroll);
                overlayRef.current.addEventListener('scroll', onScroll, { passive: true });
            }
        }
    }, [inputRef]);
    const locationSearchHeadlineLabel = useI18n({
        id: `nemo.sc.filter.locationfilter.locationSearchHeadlineLabel`,
        defaultMessage: 'Location',
    });
    const locationSearchInputLabel = useI18n({
        id: `nemo.sc.filter.locationfilter.locationSearchInputLabel`,
        defaultMessage: 'Search for a place or postal code.',
    });
    const clearLocationSearchInputButtonAriaLabel = useI18n({
        id: `nemo.sc.filter.locationfilter.clearLocationSearchInputButtonAriaLabel`,
        defaultMessage: 'Clear the text in the location search input field.',
    });
    const locateMeButtonAriaLabel = useI18n({
        id: `nemo.sc.filter.locationfilter.cookieConsentRequestMap.locateMeButtonAriaLabel`,
        defaultMessage: 'Use your location.',
    });
    const distanceRadiusLabel = useI18n({
        id: `nemo.sc.filter.locationfilter.distanceRadiusLabel`,
        defaultMessage: 'Radius',
    });
    const clearAllLocationsButtonText = useI18n({
        id: `nemo.sc.filter.locationfilter.clearAllLocationsButtonText`,
        defaultMessage: 'Reset location filter',
    });
    const locationIsSearchedOrDealerIsActive = searchedCoords !== undefined || isSomeDealerActive;
    const autocompleteCSS = `
    .pac-container {
      background-color: ${lightMode ? '#ffffff' : '#636363'};
      padding-block: 24px 24px;
      padding-inline: 16px 16px;
    }
    .pac-icon {
      display: none;
    }
   .pac-item,.pac-item-query, .pac-matched {
      color: ${lightMode ? '#333333' : '#ccc'};
      font-size: 16px;
      font-family: AudiType,sans-serif;
      font-stretch: 105%;
      line-height: 24px;
      font-weight: 400;
      border-top: none;
    }
    .pac-item{
      padding: 0px;
    }
     .pac-item:not(:last-child) {
      border-block-end: 1px solid ${lightMode ? '#d9d9d9' : '#4C4C4C'};
      padding-block-end: 10px; 
    }
    .pac-item:not(:first-child) {
      padding-block-start: 10px; 
    }
    .pac-item:hover, .pac-item-selected, .pac-item-selected:hover{
      background-color: ${lightMode ? '#f2f2f2' : '#636363'};
      padding-inline-start: 8px;
      cursor: pointer;
    }
    .pac-item:hover span, .pac-item-selected:hover span, .pac-item-selected span{
      color: ${lightMode ? '#000000' : '#ffffff'};
    }
    .marker-with-counter{
      margin-top: -6px;
    }
  `;
    return (React.createElement(LocationFilterHeaderContainer, null,
        isMobile && (React.createElement(Text, { as: "h4", variant: "order3", spaceStackEnd: "l" }, locationSearchHeadlineLabel)),
        !consent && !isDesktop && (React.createElement(ConsentRequestWrapperMobile, null,
            React.createElement(CookieConsentRequest, { mapConsentButtonText: mapConsentButtonText, openConsentOverlay: openConsentOverlay, setIsTwoClickOverlayOpened: setIsTwoClickOverlayOpened }))),
        React.createElement(LocationInputContainer, null,
            React.createElement(SearchWrapper, null,
                React.createElement("style", null, autocompleteCSS),
                React.createElement(Search, { inputRef: inputRef, disabled: !consent, value: undefined, inputId: "locationSearchInput", label: locationSearchInputLabel, showClearButton: consent, ariaLabel: clearLocationSearchInputButtonAriaLabel, placeholder: "" }),
                React.createElement(LocateMeButton, { variant: "icon-tertiary", icon: "gps", size: "small", disabled: !consent, onClick: () => handleLocateMeClick(), "aria-label": locateMeButtonAriaLabel })),
            React.createElement(StyledSelect, { inputId: "select_radius", label: distanceRadiusLabel, onChange: (e) => {
                    setRadius(e.target.value);
                }, value: radius, disabled: !consent || !radius, hideLabelOptional: true }, radiusOptions.map((radiusOption, index) => {
                return (React.createElement("option", { value: radiusOption, key: index },
                    "+ ",
                    radiusOption,
                    " ",
                    mileageUnit || 'km'));
            })),
            React.createElement(ClearAllLocationsButton, { variant: "text", icon: "replay", size: "medium", disabled: !locationIsSearchedOrDealerIsActive, onClick: () => setLocationFilterToDefaultState(getConfiguration(), getEnvironmentConfig(), clearAllDealerFilters, context, inputRef) }, clearAllLocationsButtonText))));
};
export default LocationFilterHeader;
