import React, { useCallback } from 'react';
import { useI18n } from '@oneaudi/i18n-context';
import { DealersListHeaderWrapper, DealersFoundContainer, DealersSelectedContainer, StyledIcon, } from '../styles/LocationFilter.styles';
import { Text } from '@audi/audi-ui-react-v2';
import { useFilterData } from '../FilterContextHooks';
export const DealersListHeader = ({ dealersList, }) => {
    var _a, _b;
    const filterData = useFilterData();
    const noDealersFoundLabel = useI18n({
        id: `nemo.sc.filter.locationfilter.noDealersFound`,
        defaultMessage: 'dealers found',
    });
    const singleDealerFoundLabel = useI18n({
        id: `nemo.sc.filter.locationfilter.singleDealerFound`,
        defaultMessage: 'dealer found',
    });
    const multipleDealersFoundLabel = useI18n({
        id: `nemo.sc.filter.locationfilter.multipleDealersFound`,
        defaultMessage: 'dealers found',
    });
    const selectedDealersLabel = useI18n({
        id: `nemo.sc.filter.locationfilter.selectedDealers`,
        defaultMessage: 'dealers selected',
    });
    const amountOfDealersFound = (dealersList === null || dealersList === void 0 ? void 0 : dealersList.length) === 0
        ? noDealersFoundLabel
        : (dealersList === null || dealersList === void 0 ? void 0 : dealersList.length) === 1
            ? singleDealerFoundLabel
            : multipleDealersFoundLabel;
    const getAmountOfChosenDealers = useCallback((dealerData) => {
        let dealersChosen = 0;
        if (dealerData) {
            dealerData === null || dealerData === void 0 ? void 0 : dealerData.selectedFilter.forEach((item) => {
                if (item.id !== 'geo')
                    dealersChosen++;
            });
        }
        return dealersChosen;
    }, [filterData]);
    return (React.createElement(DealersListHeaderWrapper, null,
        React.createElement(DealersFoundContainer, null,
            React.createElement(Text, { as: "p", variant: "copy1", weight: "bold", spaceInlineEnd: "xs" }, dealersList === null || dealersList === void 0 ? void 0 : dealersList.length),
            React.createElement(Text, { as: "p", variant: "copy1" }, amountOfDealersFound)),
        getAmountOfChosenDealers((_a = filterData === null || filterData === void 0 ? void 0 : filterData.filterOptions) === null || _a === void 0 ? void 0 : _a.dealer) > 0 && (React.createElement(DealersSelectedContainer, null,
            React.createElement(StyledIcon, { name: "select", size: "small", variant: "primary" }),
            React.createElement(Text, { as: "span", variant: "copy2" },
                getAmountOfChosenDealers((_b = filterData === null || filterData === void 0 ? void 0 : filterData.filterOptions) === null || _b === void 0 ? void 0 : _b.dealer),
                " ",
                selectedDealersLabel)))));
};
export default DealersListHeader;
