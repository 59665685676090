var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useMemo, useState } from 'react';
import { getSortParam } from './utils/filterHelper';
import { useStore } from 'react-redux';
import { updateCheckboxFilterTexts, formatFiltersForCheckboxes, getFiltersInCategoryArray, formatEquipmentFilterData, formatFilterCategories, addEquipmentFilterToFilterGroups, } from './utils';
import { useServicesContext } from '../../context';
import { useSvd } from '../../hooks';
import { useQuery } from '@oneaudi/onegraph-client';
import { CarlinesQuery } from './one-graph-api/carlines.query';
import { getCarlineNamesAndGroupsStructure, prepareModelFilterData } from './filterService';
export const FilterContext = React.createContext({
    store: null,
    sortParam: '',
    impressionTrackingCategoriesFired: [],
    setImpressionTrackingCategoriesFired: () => { },
    isFilterOverlayOpened: false,
    setIsFilterOverlayOpened: () => { },
    configuredFilters: [],
    equipmentFilterProperlyFormatted: [],
    newPickedContentSection: '',
    setNewPickedContentSection: () => { },
    isPlpFlag: false,
    formattedCheckboxFilterDataFromSCS: [],
    setFormattedCheckboxFilterDataFromSCS: () => { },
    modelFilterData: null,
    setModelFilterData: () => { },
    setFilterData: () => { },
    setWholeMarketDealerData: () => { },
    wholeMarketDealersFetch: true,
    setWholeMarketDealersFetch: () => { },
    radius: '',
    setRadius: () => { },
    prevSearchedCoords: undefined,
    setPrevSearchedCoords: () => { },
    searchedCoords: undefined,
    setSearchedCoords: () => { },
    handleClearLocationInputAndRadiusButKeepSelectedDealers: () => { },
});
export const FilterContextProvider = ({ configurationService, initialFilterData, children, rangeScopes, initialWholeMarketDealerData, isPlp, }) => {
    var _a, _b;
    const { getConfiguration, getEnvironmentConfig } = useServicesContext();
    const audiContentService = useServicesContext().getAdditionalService('audi-content-service');
    const { carlinePhotos } = audiContentService.getContent().fields;
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const contentService = useServicesContext().getAdditionalService('audi-content-service');
    const { countryCode: country, language } = localeService;
    const sortParam = getSortParam(configurationService);
    const svd = useSvd();
    const { filterCategories, equipmentFilter_introCategory, equipmentFilter_subCategories } = contentService.getContent().fields;
    const configEquipmentFilterCategory = ((_b = (_a = filterCategories === null || filterCategories === void 0 ? void 0 : filterCategories.find((cat) => { var _a; return ((_a = cat === null || cat === void 0 ? void 0 : cat.fields) === null || _a === void 0 ? void 0 : _a.filterGroup01) === 'equipment.equipment-block'; })) === null || _a === void 0 ? void 0 : _a.fields) === null || _b === void 0 ? void 0 : _b.filterCategory) || 'Equipment';
    const equipmentFilterProperlyFormatted = equipmentFilter_introCategory && equipmentFilter_subCategories
        ? formatEquipmentFilterData(configEquipmentFilterCategory, equipmentFilter_introCategory, equipmentFilter_subCategories)
        : [];
    const filterCategoriesFormatted = formatFilterCategories(filterCategories);
    const allConfiguredFilterCategories = addEquipmentFilterToFilterGroups(equipmentFilterProperlyFormatted, filterCategoriesFormatted);
    const [isPlpFlag] = useState(isPlp);
    const [isFilterOverlayOpened, setIsFilterOverlayOpened] = useState(false);
    const [configuredFilters, setConfiguredFilters] = useState([]);
    const [newPickedContentSection, setNewPickedContentSection] = useState(null);
    const [filterData, setFilterData] = useState(initialFilterData);
    const [rangeScope] = useState(rangeScopes);
    const [impressionTrackingCategoriesFired, setImpressionTrackingCategoriesFired] = useState([]);
    const [formattedCheckboxFilterDataFromSCS, setFormattedCheckboxFilterDataFromSCS] = useState(null);
    const [checkboxFilterTextsFromSCS, setCheckboxFilterTextsFromSCS] = useState(null);
    const [modelFilterData, setModelFilterData] = useState();
    const [wholeMarketDealerData, setWholeMarketDealerData] = useState(initialWholeMarketDealerData);
    const [wholeMarketDealersFetch, setWholeMarketDealersFetch] = useState(false);
    const [radius, setRadius] = useState(undefined);
    const [prevSearchedCoords, setPrevSearchedCoords] = useState();
    const [searchedCoords, setSearchedCoords] = useState(undefined);
    const handleClearLocationInputAndRadiusButKeepSelectedDealers = () => {
        setRadius(undefined);
        setSearchedCoords(undefined);
        setPrevSearchedCoords(undefined);
        setWholeMarketDealersFetch(true);
    };
    useEffect(() => {
        allConfiguredFilterCategories.map((filterCategory) => {
            const filtersInCategoryArray = getFiltersInCategoryArray(filterCategory);
            return setConfiguredFilters((prevObj) => [
                ...prevObj,
                {
                    filterCategory: filterCategory.filterCategory,
                    filterGroups: filtersInCategoryArray,
                    isEquipmentFilterCategory: !!filterCategory.isEquipmentFilterCategory,
                    isEquipmentFilter: !!filterCategory.isEquipmentFilter,
                },
            ]);
        });
        const fetchCheckboxFilterTexts = () => __awaiter(void 0, void 0, void 0, function* () {
            const checkboxFilterTexts = yield updateCheckboxFilterTexts(getEnvironmentConfig().scs.baseUrl, getConfiguration().scs.scsMarketPath, getEnvironmentConfig().scs.defaultApiVersion, svd.svd, sortParam);
            setCheckboxFilterTextsFromSCS(checkboxFilterTexts === null || checkboxFilterTexts === void 0 ? void 0 : checkboxFilterTexts.parsedBody);
        });
        fetchCheckboxFilterTexts().catch(console.error);
    }, []);
    useEffect(() => {
        if (!filterData || !checkboxFilterTextsFromSCS) {
            return;
        }
        const formattedFilters = formatFiltersForCheckboxes(filterData.groups, checkboxFilterTextsFromSCS.groups);
        if (formattedFilters !== formattedCheckboxFilterDataFromSCS) {
            setFormattedCheckboxFilterDataFromSCS(formattedFilters);
        }
    }, [checkboxFilterTextsFromSCS]);
    const { loading, error, data } = useQuery(CarlinesQuery, {
        variables: {
            carlineStructureIdentifier: {
                country,
                language,
            },
        },
    });
    useEffect(() => {
        if (error) {
            console.error(error);
        }
        if (data) {
            const fetchCarlineNamesAndGroupsStructure = () => __awaiter(void 0, void 0, void 0, function* () {
                const carlineNamesAndGroupsStructure = yield getCarlineNamesAndGroupsStructure(getEnvironmentConfig().scs.baseUrl, getConfiguration().scs.scsMarketPath, getEnvironmentConfig().scs.defaultApiVersion, svd.svd);
                const preparedModelFilterData = prepareModelFilterData(data.carlineStructure.carlineGroups, carlineNamesAndGroupsStructure, carlinePhotos);
                setModelFilterData(preparedModelFilterData);
            });
            fetchCarlineNamesAndGroupsStructure();
        }
    }, [loading, error, data]);
    const store = useStore();
    const filterContextValue = useMemo(() => ({
        sortParam,
        filterData,
        setFilterData,
        impressionTrackingCategoriesFired,
        setImpressionTrackingCategoriesFired,
        isFilterOverlayOpened,
        setIsFilterOverlayOpened,
        configuredFilters,
        equipmentFilterProperlyFormatted,
        newPickedContentSection,
        setNewPickedContentSection,
        isPlpFlag,
        formattedCheckboxFilterDataFromSCS,
        setFormattedCheckboxFilterDataFromSCS,
        modelFilterData,
        setModelFilterData,
        store,
        rangeScope,
        wholeMarketDealerData,
        setWholeMarketDealerData,
        wholeMarketDealersFetch,
        setWholeMarketDealersFetch,
        radius,
        setRadius,
        prevSearchedCoords,
        setPrevSearchedCoords,
        searchedCoords,
        setSearchedCoords,
        handleClearLocationInputAndRadiusButKeepSelectedDealers,
    }), [
        sortParam,
        filterData,
        setFilterData,
        impressionTrackingCategoriesFired,
        setImpressionTrackingCategoriesFired,
        isFilterOverlayOpened,
        setIsFilterOverlayOpened,
        configuredFilters,
        equipmentFilterProperlyFormatted,
        newPickedContentSection,
        setNewPickedContentSection,
        isPlpFlag,
        formattedCheckboxFilterDataFromSCS,
        setFormattedCheckboxFilterDataFromSCS,
        modelFilterData,
        setModelFilterData,
        store,
        rangeScope,
        wholeMarketDealerData,
        setWholeMarketDealerData,
        wholeMarketDealersFetch,
        setWholeMarketDealersFetch,
        radius,
        setRadius,
        prevSearchedCoords,
        setPrevSearchedCoords,
        searchedCoords,
        setSearchedCoords,
        handleClearLocationInputAndRadiusButKeepSelectedDealers,
    ]);
    return React.createElement(FilterContext.Provider, { value: filterContextValue }, children);
};
