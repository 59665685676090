import React, { useState, useEffect } from 'react';
import { StyledCheckboxColorTile, StyledColorNameLabel, StyledCountLabel, } from '../styles/CheckboxColorTile.styles';
import { formatCount } from '../../../utils';
import { useServicesContext } from '../../../context';
import { useApplyFilters } from '../FilterContextHooks';
import { FilterContext } from '../FilterContext';
const availableColorTiles = {
    exterior: [
        'beige',
        'black',
        'blue',
        'bronze',
        'brown',
        'default',
        'gold',
        'gray',
        'green',
        'orange',
        'red',
        'silver',
        'violet',
        'white',
        'yellow',
    ],
    interior: [
        'beige',
        'black',
        'blue',
        'brown',
        'default',
        'gray',
        'green',
        'red',
        'white',
        'yellow',
    ],
};
export const CheckboxColorTile = ({ groupHeadline, filterCategory, itemHeadline, itemText, visibleIn, color, isPlpQuickFilters, }) => {
    const applyFilters = useApplyFilters();
    const context = React.useContext(FilterContext);
    const [active, setActive] = useState(false);
    const [count, setCount] = useState();
    const isDisabled = count <= 0;
    const [isChecked, setIsChecked] = useState(false);
    const handleChange = () => {
        const filterDTO = {
            id: itemHeadline,
            active: !isChecked,
        };
        const currentFilter = { id: itemHeadline, values: [] };
        const trackingEventName = !isChecked ? 'vtp filter - add filter' : 'vtp filter - remove filter';
        const trackingData = {
            trackingEventName,
            trackingLabel: itemText,
            trackingValue: filterCategory,
            changedFilter: [currentFilter],
        };
        setIsChecked(!isChecked);
        applyFilters(filterDTO, trackingData);
    };
    useEffect(() => {
        const checkboxFiltersData = context.filterData.groups;
        const checkboxFilterData = checkboxFiltersData[groupHeadline].members[itemHeadline];
        setActive(checkboxFilterData.active);
        setCount(checkboxFilterData.count);
    }, [context]);
    useEffect(() => {
        setIsChecked(active);
    }, [active]);
    const { colorType, colorName } = color;
    const isColorTileAvailableForThisColor = availableColorTiles[colorType].includes(colorName);
    const { featureAppConfig } = useServicesContext();
    const localeService = useServicesContext().getAdditionalService('gfa:locale-service');
    const { assetUrl } = featureAppConfig;
    const locale = `${localeService.language}-${localeService.countryCode}`;
    const colorUrl = isColorTileAvailableForThisColor
        ? `${assetUrl}/img/colortiles/${colorType}-color.${colorName}.png`
        : `${assetUrl}/img/colortiles/fallback.png`;
    return (React.createElement(StyledCheckboxColorTile, { hideLabelOptional: true, inputId: `checkbox${itemHeadline}_${visibleIn}`, checked: isChecked, spaceStackEnd: "l", disabled: isDisabled, onChange: handleChange, colorUrl: colorUrl, isPlpQuickFilters: isPlpQuickFilters }, !isPlpQuickFilters && (React.createElement(React.Fragment, null,
        React.createElement(StyledColorNameLabel, null, itemText),
        ' ',
        React.createElement(StyledCountLabel, null,
            "(",
            formatCount(count, locale).toString(),
            ")")))));
};
